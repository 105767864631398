@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 :root {
  --blue: #1c6be1;
  --indigo: #6610f2;
  --purple: #796eff;
  --pink: #e83e8c;
  --red: #da4b48;
  --orange: #fd7e14;
  --yellow: #ffbc42;
  --green: #28a745;
  --teal: #21c87a;
  --cyan: #00dffc;
  --white: #fff;
  --gray: #8c98a4;
  --gray-dark: #343a40;
  --primary: #21c87a;
  --secondary: #646f79;
  --success: #21c87a;
  --info: #00dffc;
  --warning: #ffbc42;
  --danger: #da4b48;
  --light: #f5f8fb;
  --dark: #151b26;
  --purple: #796eff;
  --blue: #1c6be1;
  --primary-soft: rgba(33, 200, 122, 0.1);
  --white-soft: rgba(255, 255, 255, 0.1);
  --dark-soft: rgba(21, 27, 38, 0.4);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #21c87a;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #168652;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8c98a4;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.5;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e3e6f0;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #8c98a4;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #8c98a4;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.125rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-order: -1;
          order: -1;
}

.order-last {
  -webkit-order: 13;
          order: 13;
}

.order-0 {
  -webkit-order: 0;
          order: 0;
}

.order-1 {
  -webkit-order: 1;
          order: 1;
}

.order-2 {
  -webkit-order: 2;
          order: 2;
}

.order-3 {
  -webkit-order: 3;
          order: 3;
}

.order-4 {
  -webkit-order: 4;
          order: 4;
}

.order-5 {
  -webkit-order: 5;
          order: 5;
}

.order-6 {
  -webkit-order: 6;
          order: 6;
}

.order-7 {
  -webkit-order: 7;
          order: 7;
}

.order-8 {
  -webkit-order: 8;
          order: 8;
}

.order-9 {
  -webkit-order: 9;
          order: 9;
}

.order-10 {
  -webkit-order: 10;
          order: 10;
}

.order-11 {
  -webkit-order: 11;
          order: 11;
}

.order-12 {
  -webkit-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #e3e6f0;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3e6f0;
}

.table tbody + tbody {
  border-top: 2px solid #e3e6f0;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #e3e6f0;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #e3e6f0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f8fb;
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c1f0da;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8ce2ba;
}

.table-hover .table-primary:hover {
  background-color: #acebce;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #acebce;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d4d7d9;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #aeb4b9;
}

.table-hover .table-secondary:hover {
  background-color: #c6cacd;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c6cacd;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c1f0da;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8ce2ba;
}

.table-hover .table-success:hover {
  background-color: #acebce;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #acebce;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8f6fe;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7aeefd;
}

.table-hover .table-info:hover {
  background-color: #9ff3fe;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #9ff3fe;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecca;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdc9d;
}

.table-hover .table-warning:hover {
  background-color: #ffe3b1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe3b1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5cdcc;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eca1a0;
}

.table-hover .table-danger:hover {
  background-color: #f1b8b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b8b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafbfd;
}

.table-hover .table-light:hover {
  background-color: #e9f0f8;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e9f0f8;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bdbfc2;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #85888e;
}

.table-hover .table-dark:hover {
  background-color: #b0b2b6;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b0b2b6;
}

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d9d6ff;
}

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #b9b4ff;
}

.table-hover .table-purple:hover {
  background-color: #c1bdff;
}

.table-hover .table-purple:hover > td,
.table-hover .table-purple:hover > th {
  background-color: #c1bdff;
}

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #bfd6f7;
}

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #89b2ef;
}

.table-hover .table-blue:hover {
  background-color: #a8c7f4;
}

.table-hover .table-blue:hover > td,
.table-hover .table-blue:hover > th {
  background-color: #a8c7f4;
}

.table-primary-soft,
.table-primary-soft > th,
.table-primary-soft > td {
  background-color: rgba(251, 254, 252, 0.748);
}

.table-primary-soft th,
.table-primary-soft td,
.table-primary-soft thead th,
.table-primary-soft tbody + tbody {
  border-color: rgba(243, 252, 248, 0.532);
}

.table-hover .table-primary-soft:hover {
  background-color: rgba(231, 249, 237, 0.748);
}

.table-hover .table-primary-soft:hover > td,
.table-hover .table-primary-soft:hover > th {
  background-color: rgba(231, 249, 237, 0.748);
}

.table-white-soft,
.table-white-soft > th,
.table-white-soft > td {
  background-color: rgba(255, 255, 255, 0.748);
}

.table-white-soft th,
.table-white-soft td,
.table-white-soft thead th,
.table-white-soft tbody + tbody {
  border-color: rgba(255, 255, 255, 0.532);
}

.table-hover .table-white-soft:hover {
  background-color: rgba(242, 242, 242, 0.748);
}

.table-hover .table-white-soft:hover > td,
.table-hover .table-white-soft:hover > th {
  background-color: rgba(242, 242, 242, 0.748);
}

.table-dark-soft,
.table-dark-soft > th,
.table-dark-soft > td {
  background-color: rgba(234, 235, 236, 0.832);
}

.table-dark-soft th,
.table-dark-soft td,
.table-dark-soft thead th,
.table-dark-soft tbody + tbody {
  border-color: rgba(205, 206, 209, 0.688);
}

.table-hover .table-dark-soft:hover {
  background-color: rgba(221, 222, 224, 0.832);
}

.table-hover .table-dark-soft:hover > td,
.table-hover .table-dark-soft:hover > th {
  background-color: rgba(221, 222, 224, 0.832);
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #646f79;
  background-color: #8f95a0;
  border-color: #e3e6f0;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.7em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #151b26;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #151b26;
  background-color: #fff;
  border-color: #e3e6f0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control::placeholder {
  color: #8c98a4;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #f5f8fb;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #151b26;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
}

.col-form-label-lg {
  padding-top: calc(1.125rem + 1px);
  padding-bottom: calc(1.125rem + 1px);
  font-size: 1rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-bottom: 0;
  line-height: 1.7;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.125rem;
}

.form-control-lg {
  height: calc(1.5em + 2.25rem + 2px);
  padding: 1.125rem 1.125rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #8c98a4;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #21c87a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #fff;
  background-color: #21c87a;
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #21c87a;
  padding-right: calc(1.7em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2321c87a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.425em + 0.375rem);
  background-size: calc(0.85em + 0.75rem) calc(0.85em + 0.75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #21c87a;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.7em + 1.5rem);
  background-position: top calc(0.425em + 0.375rem) right calc(0.425em + 0.375rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #21c87a;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2321c87a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.85em + 0.75rem) calc(0.85em + 0.75rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #21c87a;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #21c87a;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #21c87a;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #21c87a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #3ddf93;
  background-color: #3ddf93;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #21c87a;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #21c87a;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #21c87a;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #da4b48;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #fff;
  background-color: #da4b48;
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #da4b48;
  padding-right: calc(1.7em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23da4b48' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23da4b48' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.425em + 0.375rem);
  background-size: calc(0.85em + 0.75rem) calc(0.85em + 0.75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #da4b48;
  box-shadow: 0 0 0 0.2rem rgba(218, 75, 72, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 1.5rem);
  background-position: top calc(0.425em + 0.375rem) right calc(0.425em + 0.375rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #da4b48;
  padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23da4b48' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23da4b48' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2rem/calc(0.85em + 0.75rem) calc(0.85em + 0.75rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #da4b48;
  box-shadow: 0 0 0 0.2rem rgba(218, 75, 72, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #da4b48;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #da4b48;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #da4b48;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e37572;
  background-color: #e37572;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(218, 75, 72, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #da4b48;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #da4b48;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #da4b48;
  box-shadow: 0 0 0 0.2rem rgba(218, 75, 72, 0.25);
}

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 0.1rem solid transparent;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.7;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1ca766;
  border-color: #1a9c5f;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 208, 142, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1a9c5f;
  border-color: #189159;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 208, 142, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #535c64;
  border-color: #4d555d;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 133, 141, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4d555d;
  border-color: #474f56;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 133, 141, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-success:hover {
  color: #fff;
  background-color: #1ca766;
  border-color: #1a9c5f;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 208, 142, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1a9c5f;
  border-color: #189159;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 208, 142, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-info:hover {
  color: #fff;
  background-color: #00bdd6;
  border-color: #00b2c9;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 195, 220, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00b2c9;
  border-color: #00a7bc;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(5, 195, 220, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffbc42;
  border-color: #ffbc42;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffae1c;
  border-color: #ffaa0f;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 165, 62, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffbc42;
  border-color: #ffbc42;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffaa0f;
  border-color: #ffa502;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 165, 62, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #da4b48;
  border-color: #da4b48;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d12e2a;
  border-color: #c72b28;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 102, 99, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #da4b48;
  border-color: #da4b48;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c72b28;
  border-color: #bc2926;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 102, 99, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-light:hover {
  color: #212529;
  background-color: #dae5f0;
  border-color: #d1dfec;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 216, 220, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #d1dfec;
  border-color: #c7d8e9;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 216, 220, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #151b26;
  border-color: #151b26;
}

.btn-dark:hover {
  color: #fff;
  background-color: #07090d;
  border-color: #030405;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 61, 71, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #151b26;
  border-color: #151b26;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #030405;
  border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(56, 61, 71, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #796eff;
  border-color: #796eff;
}

.btn-purple:hover {
  color: #fff;
  background-color: #5648ff;
  border-color: #4a3bff;
}

.btn-purple:focus, .btn-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 132, 255, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #796eff;
  border-color: #796eff;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #4a3bff;
  border-color: #3e2eff;
}

.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(141, 132, 255, 0.5);
}

.btn-blue {
  color: #fff;
  background-color: #1c6be1;
  border-color: #1c6be1;
}

.btn-blue:hover {
  color: #fff;
  background-color: #185bbf;
  border-color: #1655b4;
}

.btn-blue:focus, .btn-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 129, 230, 0.5);
}

.btn-blue.disabled, .btn-blue:disabled {
  color: #fff;
  background-color: #1c6be1;
  border-color: #1c6be1;
}

.btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
.show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #1655b4;
  border-color: #1550a8;
}

.btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 129, 230, 0.5);
}

.btn-primary-soft {
  color: #fff;
  background-color: rgba(33, 200, 122, 0.1);
  border-color: rgba(33, 200, 122, 0.1);
}

.btn-primary-soft:hover {
  color: #fff;
  background-color: rgba(28, 167, 102, 0.1);
  border-color: rgba(26, 156, 95, 0.1);
}

.btn-primary-soft:focus, .btn-primary-soft.focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 242, 224, 0.5);
}

.btn-primary-soft.disabled, .btn-primary-soft:disabled {
  color: #fff;
  background-color: rgba(33, 200, 122, 0.1);
  border-color: rgba(33, 200, 122, 0.1);
}

.btn-primary-soft:not(:disabled):not(.disabled):active, .btn-primary-soft:not(:disabled):not(.disabled).active,
.show > .btn-primary-soft.dropdown-toggle {
  color: #fff;
  background-color: rgba(26, 156, 95, 0.1);
  border-color: rgba(24, 145, 89, 0.1);
}

.btn-primary-soft:not(:disabled):not(.disabled):active:focus, .btn-primary-soft:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 242, 224, 0.5);
}

.btn-white-soft {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.btn-white-soft:hover {
  color: #212529;
  background-color: rgba(236, 236, 236, 0.1);
  border-color: rgba(230, 230, 230, 0.1);
}

.btn-white-soft:focus, .btn-white-soft.focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 87, 90, 0.5);
}

.btn-white-soft.disabled, .btn-white-soft:disabled {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.btn-white-soft:not(:disabled):not(.disabled):active, .btn-white-soft:not(:disabled):not(.disabled).active,
.show > .btn-white-soft.dropdown-toggle {
  color: #212529;
  background-color: rgba(230, 230, 230, 0.1);
  border-color: rgba(223, 223, 223, 0.1);
}

.btn-white-soft:not(:disabled):not(.disabled):active:focus, .btn-white-soft:not(:disabled):not(.disabled).active:focus,
.show > .btn-white-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 87, 90, 0.5);
}

.btn-dark-soft {
  color: #fff;
  background-color: rgba(21, 27, 38, 0.4);
  border-color: rgba(21, 27, 38, 0.4);
}

.btn-dark-soft:hover {
  color: #fff;
  background-color: rgba(7, 9, 13, 0.4);
  border-color: rgba(3, 4, 5, 0.4);
}

.btn-dark-soft:focus, .btn-dark-soft.focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 121, 128, 0.5);
}

.btn-dark-soft.disabled, .btn-dark-soft:disabled {
  color: #fff;
  background-color: rgba(21, 27, 38, 0.4);
  border-color: rgba(21, 27, 38, 0.4);
}

.btn-dark-soft:not(:disabled):not(.disabled):active, .btn-dark-soft:not(:disabled):not(.disabled).active,
.show > .btn-dark-soft.dropdown-toggle {
  color: #fff;
  background-color: rgba(3, 4, 5, 0.4);
  border-color: rgba(0, 0, 0, 0.4);
}

.btn-dark-soft:not(:disabled):not(.disabled):active:focus, .btn-dark-soft:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 121, 128, 0.5);
}

.btn-outline-primary {
  color: #21c87a;
  border-color: #21c87a;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #21c87a;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.btn-outline-secondary {
  color: #646f79;
  border-color: #646f79;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 111, 121, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #646f79;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(100, 111, 121, 0.5);
}

.btn-outline-success {
  color: #21c87a;
  border-color: #21c87a;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #21c87a;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.btn-outline-info {
  color: #00dffc;
  border-color: #00dffc;
}

.btn-outline-info:hover {
  color: #212529;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #00dffc;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #00dffc;
  border-color: #00dffc;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.btn-outline-warning {
  color: #ffbc42;
  border-color: #ffbc42;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc42;
  border-color: #ffbc42;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 66, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbc42;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc42;
  border-color: #ffbc42;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 66, 0.5);
}

.btn-outline-danger {
  color: #da4b48;
  border-color: #da4b48;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #da4b48;
  border-color: #da4b48;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 75, 72, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #da4b48;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #da4b48;
  border-color: #da4b48;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 75, 72, 0.5);
}

.btn-outline-light {
  color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f5f8fb;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
}

.btn-outline-dark {
  color: #151b26;
  border-color: #151b26;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #151b26;
  border-color: #151b26;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 27, 38, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #151b26;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #151b26;
  border-color: #151b26;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 27, 38, 0.5);
}

.btn-outline-purple {
  color: #796eff;
  border-color: #796eff;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #796eff;
  border-color: #796eff;
}

.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 110, 255, 0.5);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #796eff;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #796eff;
  border-color: #796eff;
}

.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(121, 110, 255, 0.5);
}

.btn-outline-blue {
  color: #1c6be1;
  border-color: #1c6be1;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #1c6be1;
  border-color: #1c6be1;
}

.btn-outline-blue:focus, .btn-outline-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 107, 225, 0.5);
}

.btn-outline-blue.disabled, .btn-outline-blue:disabled {
  color: #1c6be1;
  background-color: transparent;
}

.btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue.dropdown-toggle {
  color: #fff;
  background-color: #1c6be1;
  border-color: #1c6be1;
}

.btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 107, 225, 0.5);
}

.btn-outline-primary-soft {
  color: rgba(33, 200, 122, 0.1);
  border-color: rgba(33, 200, 122, 0.1);
}

.btn-outline-primary-soft:hover {
  color: #fff;
  background-color: rgba(33, 200, 122, 0.1);
  border-color: rgba(33, 200, 122, 0.1);
}

.btn-outline-primary-soft:focus, .btn-outline-primary-soft.focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.btn-outline-primary-soft.disabled, .btn-outline-primary-soft:disabled {
  color: rgba(33, 200, 122, 0.1);
  background-color: transparent;
}

.btn-outline-primary-soft:not(:disabled):not(.disabled):active, .btn-outline-primary-soft:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-soft.dropdown-toggle {
  color: #fff;
  background-color: rgba(33, 200, 122, 0.1);
  border-color: rgba(33, 200, 122, 0.1);
}

.btn-outline-primary-soft:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-soft:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.btn-outline-white-soft {
  color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.btn-outline-white-soft:hover {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.btn-outline-white-soft:focus, .btn-outline-white-soft.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white-soft.disabled, .btn-outline-white-soft:disabled {
  color: rgba(255, 255, 255, 0.1);
  background-color: transparent;
}

.btn-outline-white-soft:not(:disabled):not(.disabled):active, .btn-outline-white-soft:not(:disabled):not(.disabled).active,
.show > .btn-outline-white-soft.dropdown-toggle {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.btn-outline-white-soft:not(:disabled):not(.disabled):active:focus, .btn-outline-white-soft:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark-soft {
  color: rgba(21, 27, 38, 0.4);
  border-color: rgba(21, 27, 38, 0.4);
}

.btn-outline-dark-soft:hover {
  color: #fff;
  background-color: rgba(21, 27, 38, 0.4);
  border-color: rgba(21, 27, 38, 0.4);
}

.btn-outline-dark-soft:focus, .btn-outline-dark-soft.focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 27, 38, 0.5);
}

.btn-outline-dark-soft.disabled, .btn-outline-dark-soft:disabled {
  color: rgba(21, 27, 38, 0.4);
  background-color: transparent;
}

.btn-outline-dark-soft:not(:disabled):not(.disabled):active, .btn-outline-dark-soft:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-soft.dropdown-toggle {
  color: #fff;
  background-color: rgba(21, 27, 38, 0.4);
  border-color: rgba(21, 27, 38, 0.4);
}

.btn-outline-dark-soft:not(:disabled):not(.disabled):active:focus, .btn-outline-dark-soft:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 27, 38, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #21c87a;
  text-decoration: none;
}

.btn-link:hover {
  color: #168652;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #8c98a4;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 1.125rem 1.125rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.125rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 8.4375rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e3e6f0;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 0.375rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 0.375rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #8c98a4;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 0.375rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -0.1rem;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.btn-group-vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -0.1rem;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.75rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #8f95a0;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 2.25rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.125rem 1.125rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 1.25rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.625rem 1.125rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.125rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.7rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #21c87a;
  background-color: #21c87a;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3e6f0;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #aaf1d0;
  border-color: #aaf1d0;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #8c98a4;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f5f8fb;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.35rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #21c87a;
  background-color: #21c87a;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 200, 122, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(33, 200, 122, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 200, 122, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.35rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(33, 200, 122, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.7em + 1.5rem + 2px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #8f95a0;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none;
}

.custom-select:focus {
  border-color: rgba(21, 27, 38, 0.5);
  outline: 0;
  box-shadow: 0 0 1.5625rem rgba(100, 111, 121, 0.1);
}

.custom-select:focus::-ms-value {
  color: #151b26;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #8c98a4;
  background-color: #8f95a0;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 1.25rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.125rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 2.25rem + 2px);
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-left: 1.125rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.7em + 1.5rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.7em + 1.5rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #e3e6f0;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f5f8fb;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.7em + 1.5rem + 2px);
  padding: 0.75rem 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: #151b26;
  background-color: #fff;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.7em + 1.5rem);
  padding: 0.75rem 1rem;
  line-height: 1.7;
  color: #151b26;
  content: "Browse";
  background-color: #fff;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #21c87a;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #aaf1d0;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e3e6f0;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #21c87a;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #aaf1d0;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e3e6f0;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #21c87a;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #aaf1d0;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #e3e6f0;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #e3e6f0;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #8c98a4;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e3e6f0;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #8f95a0 #8f95a0 #e3e6f0;
}

.nav-tabs .nav-link.disabled {
  color: #8c98a4;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #646f79;
  background-color: #fff;
  border-color: #e3e6f0 #e3e6f0 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #21c87a;
}

.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-basis: auto;
          flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #e3e6f0;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid #e3e6f0;
}

.card-footer:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: 0.25rem;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-deck {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 0;
            flex: 1 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-flex: 1 0;
            flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            grid-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #8f95a0;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #8c98a4;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #8c98a4;
}

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #646f79;
  background-color: #fff;
  border: 0 solid #646f79;
}

.page-link:hover {
  z-index: 2;
  color: #21c87a;
  text-decoration: none;
  background-color: rgba(33, 200, 122, 0.1);
  border-color: #e3e6f0;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.page-item.disabled .page-link {
  color: #8c98a4;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e3e6f0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #21c87a;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1a9c5f;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #646f79;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #4d555d;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(100, 111, 121, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #21c87a;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1a9c5f;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #00dffc;
}

a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #00b2c9;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 223, 252, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffbc42;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #ffaa0f;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 66, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #da4b48;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c72b28;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(218, 75, 72, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f5f8fb;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #d1dfec;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 248, 251, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #151b26;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #030405;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(21, 27, 38, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #796eff;
}

a.badge-purple:hover, a.badge-purple:focus {
  color: #fff;
  background-color: #4a3bff;
}

a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(121, 110, 255, 0.5);
}

.badge-blue {
  color: #fff;
  background-color: #1c6be1;
}

a.badge-blue:hover, a.badge-blue:focus {
  color: #fff;
  background-color: #1655b4;
}

a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 107, 225, 0.5);
}

.badge-primary-soft {
  color: #fff;
  background-color: rgba(33, 200, 122, 0.1);
}

a.badge-primary-soft:hover, a.badge-primary-soft:focus {
  color: #fff;
  background-color: rgba(26, 156, 95, 0.1);
}

a.badge-primary-soft:focus, a.badge-primary-soft.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 200, 122, 0.5);
}

.badge-white-soft {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.1);
}

a.badge-white-soft:hover, a.badge-white-soft:focus {
  color: #212529;
  background-color: rgba(230, 230, 230, 0.1);
}

a.badge-white-soft:focus, a.badge-white-soft.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-dark-soft {
  color: #fff;
  background-color: rgba(21, 27, 38, 0.4);
}

a.badge-dark-soft:hover, a.badge-dark-soft:focus {
  color: #fff;
  background-color: rgba(3, 4, 5, 0.4);
}

a.badge-dark-soft:focus, a.badge-dark-soft.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(21, 27, 38, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #8f95a0;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.625rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #11683f;
  background-color: #d3f4e4;
  border-color: #c1f0da;
}

.alert-primary hr {
  border-top-color: #acebce;
}

.alert-primary .alert-link {
  color: #0a3c24;
}

.alert-secondary {
  color: #343a3f;
  background-color: #e0e2e4;
  border-color: #d4d7d9;
}

.alert-secondary hr {
  border-top-color: #c6cacd;
}

.alert-secondary .alert-link {
  color: #1d2023;
}

.alert-success {
  color: #11683f;
  background-color: #d3f4e4;
  border-color: #c1f0da;
}

.alert-success hr {
  border-top-color: #acebce;
}

.alert-success .alert-link {
  color: #0a3c24;
}

.alert-info {
  color: #007483;
  background-color: #ccf9fe;
  border-color: #b8f6fe;
}

.alert-info hr {
  border-top-color: #9ff3fe;
}

.alert-info .alert-link {
  color: #004750;
}

.alert-warning {
  color: #856222;
  background-color: #fff2d9;
  border-color: #ffecca;
}

.alert-warning hr {
  border-top-color: #ffe3b1;
}

.alert-warning .alert-link {
  color: #5c4418;
}

.alert-danger {
  color: #712725;
  background-color: #f8dbda;
  border-color: #f5cdcc;
}

.alert-danger hr {
  border-top-color: #f1b8b7;
}

.alert-danger .alert-link {
  color: #4b1a18;
}

.alert-light {
  color: #7f8183;
  background-color: #fdfefe;
  border-color: #fcfdfe;
}

.alert-light hr {
  border-top-color: #e9f0f8;
}

.alert-light .alert-link {
  color: #666869;
}

.alert-dark {
  color: #0b0e14;
  background-color: #d0d1d4;
  border-color: #bdbfc2;
}

.alert-dark hr {
  border-top-color: #b0b2b6;
}

.alert-dark .alert-link {
  color: black;
}

.alert-purple {
  color: #3f3985;
  background-color: #e4e2ff;
  border-color: #d9d6ff;
}

.alert-purple hr {
  border-top-color: #c1bdff;
}

.alert-purple .alert-link {
  color: #2e2a61;
}

.alert-blue {
  color: #0f3875;
  background-color: #d2e1f9;
  border-color: #bfd6f7;
}

.alert-blue hr {
  border-top-color: #a8c7f4;
}

.alert-blue .alert-link {
  color: #092248;
}

.alert-primary-soft {
  color: rgba(2, 11, 7, 0.532);
  background-color: rgba(252, 254, 253, 0.82);
  border-color: rgba(251, 254, 252, 0.748);
}

.alert-primary-soft hr {
  border-top-color: rgba(231, 249, 237, 0.748);
}

.alert-primary-soft .alert-link {
  color: rgba(0, 0, 0, 0.532);
}

.alert-white-soft {
  color: rgba(14, 14, 14, 0.532);
  background-color: rgba(255, 255, 255, 0.82);
  border-color: rgba(255, 255, 255, 0.748);
}

.alert-white-soft hr {
  border-top-color: rgba(242, 242, 242, 0.748);
}

.alert-white-soft .alert-link {
  color: rgba(0, 0, 0, 0.532);
}

.alert-dark-soft {
  color: rgba(4, 6, 8, 0.688);
  background-color: rgba(241, 242, 242, 0.88);
  border-color: rgba(234, 235, 236, 0.832);
}

.alert-dark-soft hr {
  border-top-color: rgba(221, 222, 224, 0.832);
}

.alert-dark-soft .alert-link {
  color: rgba(0, 0, 0, 0.688);
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f5f8fb;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #21c87a;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #646f79;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #21c87a;
  text-decoration: none;
  background-color: transparent;
}

.list-group-item-action:active {
  color: #21c87a;
  background-color: transparent;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.375rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e3e6f0;
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #8c98a4;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.list-group-horizontal {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #11683f;
  background-color: #c1f0da;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #11683f;
  background-color: #acebce;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #11683f;
  border-color: #11683f;
}

.list-group-item-secondary {
  color: #343a3f;
  background-color: #d4d7d9;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #343a3f;
  background-color: #c6cacd;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #343a3f;
  border-color: #343a3f;
}

.list-group-item-success {
  color: #11683f;
  background-color: #c1f0da;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #11683f;
  background-color: #acebce;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #11683f;
  border-color: #11683f;
}

.list-group-item-info {
  color: #007483;
  background-color: #b8f6fe;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007483;
  background-color: #9ff3fe;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #007483;
  border-color: #007483;
}

.list-group-item-warning {
  color: #856222;
  background-color: #ffecca;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856222;
  background-color: #ffe3b1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856222;
  border-color: #856222;
}

.list-group-item-danger {
  color: #712725;
  background-color: #f5cdcc;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #712725;
  background-color: #f1b8b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #712725;
  border-color: #712725;
}

.list-group-item-light {
  color: #7f8183;
  background-color: #fcfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7f8183;
  background-color: #e9f0f8;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7f8183;
  border-color: #7f8183;
}

.list-group-item-dark {
  color: #0b0e14;
  background-color: #bdbfc2;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0b0e14;
  background-color: #b0b2b6;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0b0e14;
  border-color: #0b0e14;
}

.list-group-item-purple {
  color: #3f3985;
  background-color: #d9d6ff;
}

.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #3f3985;
  background-color: #c1bdff;
}

.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #3f3985;
  border-color: #3f3985;
}

.list-group-item-blue {
  color: #0f3875;
  background-color: #bfd6f7;
}

.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #0f3875;
  background-color: #a8c7f4;
}

.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #0f3875;
  border-color: #0f3875;
}

.list-group-item-primary-soft {
  color: rgba(2, 11, 7, 0.532);
  background-color: rgba(251, 254, 252, 0.748);
}

.list-group-item-primary-soft.list-group-item-action:hover, .list-group-item-primary-soft.list-group-item-action:focus {
  color: rgba(2, 11, 7, 0.532);
  background-color: rgba(231, 249, 237, 0.748);
}

.list-group-item-primary-soft.list-group-item-action.active {
  color: #fff;
  background-color: rgba(2, 11, 7, 0.532);
  border-color: rgba(2, 11, 7, 0.532);
}

.list-group-item-white-soft {
  color: rgba(14, 14, 14, 0.532);
  background-color: rgba(255, 255, 255, 0.748);
}

.list-group-item-white-soft.list-group-item-action:hover, .list-group-item-white-soft.list-group-item-action:focus {
  color: rgba(14, 14, 14, 0.532);
  background-color: rgba(242, 242, 242, 0.748);
}

.list-group-item-white-soft.list-group-item-action.active {
  color: #fff;
  background-color: rgba(14, 14, 14, 0.532);
  border-color: rgba(14, 14, 14, 0.532);
}

.list-group-item-dark-soft {
  color: rgba(4, 6, 8, 0.688);
  background-color: rgba(234, 235, 236, 0.832);
}

.list-group-item-dark-soft.list-group-item-action:hover, .list-group-item-dark-soft.list-group-item-action:focus {
  color: rgba(4, 6, 8, 0.688);
  background-color: rgba(221, 222, 224, 0.832);
}

.list-group-item-dark-soft.list-group-item-action.active {
  color: #fff;
  background-color: rgba(4, 6, 8, 0.688);
  border-color: rgba(4, 6, 8, 0.688);
}

.close {
  float: right;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  color: #151b26;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #151b26;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #8c98a4;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal-dialog-scrollable {
  display: -webkit-flex;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #f5f8fb;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.7;
}

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #f5f8fb;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #21c87a !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1a9c5f !important;
}

.bg-secondary {
  background-color: #646f79 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #4d555d !important;
}

.bg-success {
  background-color: #21c87a !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1a9c5f !important;
}

.bg-info {
  background-color: #00dffc !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #00b2c9 !important;
}

.bg-warning {
  background-color: #ffbc42 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffaa0f !important;
}

.bg-danger {
  background-color: #da4b48 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c72b28 !important;
}

.bg-light {
  background-color: #f5f8fb !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d1dfec !important;
}

.bg-dark {
  background-color: #151b26 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #030405 !important;
}

.bg-purple {
  background-color: #796eff !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #4a3bff !important;
}

.bg-blue {
  background-color: #1c6be1 !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #1655b4 !important;
}

.bg-primary-soft {
  background-color: rgba(33, 200, 122, 0.1) !important;
}

a.bg-primary-soft:hover, a.bg-primary-soft:focus,
button.bg-primary-soft:hover,
button.bg-primary-soft:focus {
  background-color: rgba(26, 156, 95, 0.1) !important;
}

.bg-white-soft {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

a.bg-white-soft:hover, a.bg-white-soft:focus,
button.bg-white-soft:hover,
button.bg-white-soft:focus {
  background-color: rgba(230, 230, 230, 0.1) !important;
}

.bg-dark-soft {
  background-color: rgba(21, 27, 38, 0.4) !important;
}

a.bg-dark-soft:hover, a.bg-dark-soft:focus,
button.bg-dark-soft:hover,
button.bg-dark-soft:focus {
  background-color: rgba(3, 4, 5, 0.4) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #f5f8fb !important;
}

.border-top {
  border-top: 1px solid #f5f8fb !important;
}

.border-right {
  border-right: 1px solid #f5f8fb !important;
}

.border-bottom {
  border-bottom: 1px solid #f5f8fb !important;
}

.border-left {
  border-left: 1px solid #f5f8fb !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #21c87a !important;
}

.border-secondary {
  border-color: #646f79 !important;
}

.border-success {
  border-color: #21c87a !important;
}

.border-info {
  border-color: #00dffc !important;
}

.border-warning {
  border-color: #ffbc42 !important;
}

.border-danger {
  border-color: #da4b48 !important;
}

.border-light {
  border-color: #f5f8fb !important;
}

.border-dark {
  border-color: #151b26 !important;
}

.border-purple {
  border-color: #796eff !important;
}

.border-blue {
  border-color: #1c6be1 !important;
}

.border-primary-soft {
  border-color: rgba(33, 200, 122, 0.1) !important;
}

.border-white-soft {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-dark-soft {
  border-color: rgba(21, 27, 38, 0.4) !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.125rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important;
}

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important;
}

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important;
}

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important;
}

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important;
}

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important;
}

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important;
}

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important;
}

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important;
}

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important;
}

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important;
}

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.125) !important;
}

.shadow {
  box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.25) !important;
}

.shadow-lg {
  box-shadow: 0 0.625rem 2.5rem 0.625rem rgba(140, 152, 164, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2rem !important;
}

.m-6 {
  margin: 2.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2.5rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.m-8 {
  margin: 3.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important;
}

.m-9 {
  margin: 4rem !important;
}

.mt-9,
.my-9 {
  margin-top: 4rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 4rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 4rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2rem !important;
}

.p-6 {
  padding: 2.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2.5rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.p-8 {
  padding: 3.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 3.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 3.5rem !important;
}

.p-9 {
  padding: 4rem !important;
}

.pt-9,
.py-9 {
  padding-top: 4rem !important;
}

.pr-9,
.px-9 {
  padding-right: 4rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 4rem !important;
}

.pl-9,
.px-9 {
  padding-left: 4rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important;
}

.m-n6 {
  margin: -2.5rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2.5rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2.5rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2.5rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2.5rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-n8 {
  margin: -3.5rem !important;
}

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important;
}

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important;
}

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important;
}

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important;
}

.m-n9 {
  margin: -4rem !important;
}

.mt-n9,
.my-n9 {
  margin-top: -4rem !important;
}

.mr-n9,
.mx-n9 {
  margin-right: -4rem !important;
}

.mb-n9,
.my-n9 {
  margin-bottom: -4rem !important;
}

.ml-n9,
.mx-n9 {
  margin-left: -4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important;
  }
  .m-sm-6 {
    margin: 2.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.5rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .m-sm-8 {
    margin: 3.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important;
  }
  .m-sm-9 {
    margin: 4rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important;
  }
  .p-sm-6 {
    padding: 2.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.5rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .p-sm-8 {
    padding: 3.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important;
  }
  .p-sm-9 {
    padding: 4rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important;
  }
  .m-sm-n6 {
    margin: -2.5rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.5rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-n8 {
    margin: -3.5rem !important;
  }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.5rem !important;
  }
  .m-sm-n9 {
    margin: -4rem !important;
  }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4rem !important;
  }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4rem !important;
  }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important;
  }
  .m-md-6 {
    margin: 2.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.5rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .m-md-8 {
    margin: 3.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important;
  }
  .m-md-9 {
    margin: 4rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important;
  }
  .p-md-6 {
    padding: 2.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.5rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .p-md-8 {
    padding: 3.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important;
  }
  .p-md-9 {
    padding: 4rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important;
  }
  .m-md-n6 {
    margin: -2.5rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.5rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-n8 {
    margin: -3.5rem !important;
  }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.5rem !important;
  }
  .m-md-n9 {
    margin: -4rem !important;
  }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4rem !important;
  }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4rem !important;
  }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important;
  }
  .m-lg-6 {
    margin: 2.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.5rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .m-lg-8 {
    margin: 3.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important;
  }
  .m-lg-9 {
    margin: 4rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important;
  }
  .p-lg-6 {
    padding: 2.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.5rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .p-lg-8 {
    padding: 3.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important;
  }
  .p-lg-9 {
    padding: 4rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important;
  }
  .m-lg-n6 {
    margin: -2.5rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.5rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-n8 {
    margin: -3.5rem !important;
  }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.5rem !important;
  }
  .m-lg-n9 {
    margin: -4rem !important;
  }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4rem !important;
  }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4rem !important;
  }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important;
  }
  .m-xl-6 {
    margin: 2.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.5rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .m-xl-8 {
    margin: 3.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important;
  }
  .m-xl-9 {
    margin: 4rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important;
  }
  .p-xl-6 {
    padding: 2.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.5rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .p-xl-8 {
    padding: 3.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important;
  }
  .p-xl-9 {
    padding: 4rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important;
  }
  .m-xl-n6 {
    margin: -2.5rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.5rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.5rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.5rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-n8 {
    margin: -3.5rem !important;
  }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.5rem !important;
  }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
  }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.5rem !important;
  }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.5rem !important;
  }
  .m-xl-n9 {
    margin: -4rem !important;
  }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4rem !important;
  }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4rem !important;
  }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #21c87a !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #168652 !important;
}

.text-secondary {
  color: #646f79 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #41494f !important;
}

.text-success {
  color: #21c87a !important;
}

a.text-success:hover, a.text-success:focus {
  color: #168652 !important;
}

.text-info {
  color: #00dffc !important;
}

a.text-info:hover, a.text-info:focus {
  color: #009bb0 !important;
}

.text-warning {
  color: #ffbc42 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f59e00 !important;
}

.text-danger {
  color: #da4b48 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b22724 !important;
}

.text-light {
  color: #f5f8fb !important;
}

a.text-light:hover, a.text-light:focus {
  color: #bed2e5 !important;
}

.text-dark {
  color: #151b26 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-purple {
  color: #796eff !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #3222ff !important;
}

.text-blue {
  color: #1c6be1 !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #144b9d !important;
}

.text-primary-soft {
  color: rgba(33, 200, 122, 0.1) !important;
}

a.text-primary-soft:hover, a.text-primary-soft:focus {
  color: rgba(22, 134, 82, 0.1) !important;
}

.text-white-soft {
  color: rgba(255, 255, 255, 0.1) !important;
}

a.text-white-soft:hover, a.text-white-soft:focus {
  color: rgba(217, 217, 217, 0.1) !important;
}

.text-dark-soft {
  color: rgba(21, 27, 38, 0.4) !important;
}

a.text-dark-soft:hover, a.text-dark-soft:focus {
  color: rgba(0, 0, 0, 0.4) !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #8c98a4 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e3e6f0 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #e3e6f0;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e3e6f0;
  }
}

/*----------------------------------------------------------------------
  * Space - Responsive Website Template version v1.2
  * Copyright 2018 Htmlstream
  * Licensed under Bootstrap Themes (https://themes.getbootstrap.com/licenses/)
------------------------------------------------------------------------*/
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-15 {
    width: 15% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
  .w-sm-30 {
    width: 30% !important;
  }
  .w-sm-35 {
    width: 35% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-55 {
    width: 55% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-65 {
    width: 65% !important;
  }
  .w-sm-70 {
    width: 70% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-90 {
    width: 90% !important;
  }
}

@media (min-width: 768px) {
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .w-md-15 {
    width: 15% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
  .w-md-30 {
    width: 30% !important;
  }
  .w-md-35 {
    width: 35% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-55 {
    width: 55% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-70 {
    width: 70% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-90 {
    width: 90% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-15 {
    width: 15% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-35 {
    width: 35% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-55 {
    width: 55% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-65 {
    width: 65% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-15 {
    width: 15% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-35 {
    width: 35% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-55 {
    width: 55% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-65 {
    width: 65% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
}

/*------------------------------------
  Default Styles
------------------------------------*/
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
}

main {
  position: relative;
}

p {
  color: #646f79;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a,
.h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a {
  color: #151b26;
  font-weight: 400;
}

h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover,
.h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #168652;
}

h1.text-white > a, h2.text-white > a, h3.text-white > a, h4.text-white > a, h5.text-white > a, h6.text-white > a,
.h1.text-white > a, .h2.text-white > a, .h3.text-white > a, .h4.text-white > a, .h5.text-white > a, .h6.text-white > a {
  color: #fff;
}

h6, .h6 {
  font-weight: 500;
}

figure {
  margin-bottom: 0;
}

object {
  margin-bottom: -.3125rem;
}

.fa, .fas {
  font-weight: 900;
}

/*------------------------------------
  Highlight Color
------------------------------------*/

::selection {
  color: #fff;
  background-color: #5E83C3;
}

.bg-primary ::selection {
  color: #21c87a;
  background-color: #fff;
}

.IE11 .ie-d-block {
  display: block !important;
}

/*------------------------------------
  Accessibility
------------------------------------*/
:focus,
a:focus,
button:focus {
  outline: 0;
}

.form-control:focus {
  box-shadow: 0 0 0 0 transparent;
}

.btn:focus, .btn.focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
  box-shadow: 0 0 0 0 transparent;
}

.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus,
.show > .btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 transparent;
}

.page-link:focus {
  box-shadow: 0 0 0 0 transparent;
}

.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 transparent;
}

.custom-file-input:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0 transparent;
}

/*------------------------------------
  Skippy
------------------------------------*/
.u-skippy {
  display: none;
}

/*------------------------------------
  List Group
------------------------------------*/
.list-group {
  list-style: none;
}

.list-group .list-group {
  margin-left: 1rem;
}

.list-group .list-group-item {
  font-size: 0.875rem;
}

.list-group .active > .list-group-item {
  color: #21c87a;
}

/* Border Radius */
.list-group-rounded-0 .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-rounded-0 .list-group-item:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

/* Transparent */
.list-group-transparent .list-group-item {
  color: #fff;
  border-color: transparent;
  background-color: transparent;
}

.list-group-transparent .list-group-item[href] {
  color: rgba(255, 255, 255, 0.7);
}

.list-group-transparent .list-group-item[href]:hover {
  color: #fff;
}

.list-group-transparent.list-group-striped .list-group-item:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Flush */
.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-flush .list-group-item.active {
  color: #21c87a;
  background-color: transparent;
  border-color: transparent;
}

/* Borderless */
.list-group-borderless .list-group-item {
  border: none;
}

/* Striped */
.list-group-striped .list-group-item:nth-child(even) {
  background-color: #f5f8fb;
}

/*------------------------------------
  Header Default
------------------------------------*/
.u-header {
  position: relative;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 101;
}

.u-header__section {
  z-index: 1;
  background-color: #fff;
  transition: background-color 0.3s ease-in-out;
  border-bottom: 1px solid #e3e6f0;
}

.u-header__hamburger {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

/* Navbar */
.u-header__navbar {
  padding: 0;
}

.u-header__navbar-brand {
  width: 9.375rem;
}

.u-header__navbar-brand-mobile {
  width: 3.125rem;
}

.u-header__navbar-nav {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.u-header__navbar-icon {
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem;
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
}

.u-header__navbar-icon:last-child {
  padding-right: 0;
}

.u-header__navbar-icon-wrapper {
  margin-left: auto;
}

/* Nav Item */
.u-header__nav-item:hover .u-header__nav-link, .u-header__nav-item:focus .u-header__nav-link {
  color: #21c87a;
}

/* Nav Link */
.u-header__nav-link {
  color: #5a5757;
  font-size: 1rem;
  font-weight: 400;
}

.u-header__nav-link-icon {
  font-size: 0.625rem;
  margin-left: 0.125rem;
  transition: 0.3s;
}

.u-header .active > .u-header__nav-link {
  color: #21c87a;
}

/* Sub Menu */
.u-header__sub-menu-title {
  display: block;
  color: #151b26;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
}

.u-header__sub-menu-nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-size: 0.875rem;
  color: #5a5757;
}

.u-header__sub-menu-nav-link-icon {
  font-size: 0.625rem;
}

.u-header__sub-menu .active > .u-header__sub-menu-nav-link {
  color: #21c87a;
}

/* Search */
.u-header__search-addon {
  border: none;
}

.u-header__search-addon-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Header Fix Top */
.u-header--fix-top {
  position: fixed;
}

.u-header.u-header--fix-top[data-effect-compensation] {
  transition: none;
}

/*------------------------------------
  Header Helpers
------------------------------------*/
.u-header--invisible {
  display: none;
}

.u-header--moved-up {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
}

.u-header--faded {
  opacity: 0;
  visibility: hidden;
}

.u-header__section--hidden {
  position: relative;
}

.u-header[data-header-fix-effect] {
  transition: 0.3s ease;
}

.u-header.u-header--untransitioned {
  transition: none;
}

.u-header.js-header-fix-moment {
  position: fixed;
  top: 0;
  bottom: auto;
}

/*------------------------------------
  Transparent Header
------------------------------------*/
@media (min-width: 992px) {
  .u-header--bg-transparent:not(.js-header-fix-moment):not(.u-scrolled) .u-header__section {
    background-color: transparent;
    border-color: transparent;
  }
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__section,
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__navbar-brand,
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user,
  .u-header--bg-transparent.u-header--bordered:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__navbar-v-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--bg-transparent .u-header__navbar-brand-on-scroll {
    display: none;
  }
  .u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default, .u-header--bg-transparent.u-scrolled .u-header__navbar-brand-default {
    display: none;
  }
  .u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll, .u-header--bg-transparent.u-scrolled .u-header__navbar-brand-on-scroll {
    display: inline-block;
  }
}

@media (max-width: 991.98px) {
  .u-header--bg-transparent .u-header__navbar-brand-default {
    display: none;
  }
}

/*------------------------------------
  Header White Nav Links
------------------------------------*/
@media (min-width: 992px) {
  .u-header--white-nav-links .u-header__nav-link,
  .u-header--white-nav-links [class*="btn-text-"] {
    transition: 0.3s;
  }
  .u-header--white-nav-links .u-header__nav-link-icon {
    transition: 0s;
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item:hover .u-header__nav-link {
    color: #fff;
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-link,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user-name,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) [class*="btn-text-"] {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-link:hover,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user-name:hover,
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) [class*="btn-text-"]:hover {
    color: #fff;
  }
  .u-header--white-nav-links:not(.bg-white):not(.js-header-fix-moment):not(.u-scrolled) .u-header__nav-item-user {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

/*------------------------------------
  Default Navbar Expand
------------------------------------*/
.navbar-expand {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.u-header--bordered .navbar-expand .u-header__navbar-brand {
  border-right: 1px solid #e3e6f0;
  margin-right: 1.375rem;
}

.u-header--bordered .navbar-expand .u-header__navbar-v-divider {
  border-left: 0.0625rem solid #e3e6f0;
  margin-left: 1.375rem;
}

.u-header--modern .navbar-expand .u-header__hamburger {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
  -webkit-order: 1;
          order: 1;
}

.u-header--modern .navbar-expand .u-header__navbar-brand-wrapper {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
  -webkit-order: 1;
          order: 1;
}

.u-header--modern .navbar-expand .u-header__navbar-collapse {
  -webkit-order: 2;
          order: 2;
}

.u-header--modern .navbar-expand .u-header__secondary-nav {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: none;
  -webkit-order: 3;
          order: 3;
}

.navbar-expand .u-header__navbar-brand {
  padding-top: 1rem;
  padding-bottom: .9375rem;
  padding-right: 1rem;
}

.navbar-expand .u-header__navbar-nav {
  -webkit-align-items: center;
          align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-link {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu {
  border: 1px solid #e3e6f0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 0.5rem 1.25rem rgba(100, 111, 121, 0.1);
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-list-item {
  padding: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-link {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-offset {
  margin-top: -1.0625rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
  padding: 0;
}

.navbar-expand .u-header__mega-menu-list {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
  list-style: none;
}

.navbar-expand .u-header__mega-menu-col {
  padding-left: 2rem;
}

.navbar-expand .u-header__mega-menu-col:first-child {
  padding-left: 1.5rem;
}

.navbar-expand .u-header__mega-menu-col:not(:last-child) {
  border-right: 1px solid #e3e6f0;
}

.navbar-expand .u-header__mega-menu-wrapper-v1 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.navbar-expand .u-header__nav-item-btn {
  padding-left: 1rem;
}

.navbar-expand .u-header__nav-item-user {
  background-color: #f5f8fb;
  border-left: 1px solid #e3e6f0;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-right: 1rem;
}

.navbar-expand .u-header__nav-item-user-name {
  color: #646f79;
}

.navbar-expand .u-header__nav-item-user-name:hover {
  color: #21c87a;
}

.navbar-expand .u-header__nav-item-user + .u-header__navbar-v-divider {
  margin-left: 0;
}

.navbar-expand .u-header__navbar-v-divider {
  padding-left: 0.875rem;
}

.navbar-expand .u-header__search {
  position: absolute;
  right: 0;
  z-index: 102;
  width: 23.125rem;
  background-color: #fff;
  border: 1px solid #e3e6f0;
  box-shadow: 0 0 1.5625rem rgba(21, 27, 38, 0.1);
  padding: 1rem;
  border-radius: 0;
  margin-top: 1.3125rem;
  transition: none;
}

.navbar-expand .u-header__search.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-header--left-aligned-nav .navbar-expand .u-header__navbar-nav {
  width: 100%;
  margin-left: 0;
}

.u-header--left-aligned-nav .navbar-expand .u-header__nav-item-btn {
  margin-left: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-sm .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-sm .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-sm .u-header__hamburger {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-brand-wrapper {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-collapse {
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-sm .u-header__secondary-nav {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 3;
            order: 3;
  }
  .navbar-expand-sm .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: .9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-sm .u-header__navbar-nav {
    -webkit-align-items: center;
            align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0.5rem 1.25rem rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-sm .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-sm .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-sm .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-sm .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-sm .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-sm .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-sm .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-sm .u-header__nav-item-user-name:hover {
    color: #21c87a;
  }
  .navbar-expand-sm .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-sm .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-sm .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 1.5625rem rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-sm .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-sm .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-sm .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-md .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-md .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-md .u-header__hamburger {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-brand-wrapper {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-collapse {
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-md .u-header__secondary-nav {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 3;
            order: 3;
  }
  .navbar-expand-md .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: .9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-md .u-header__navbar-nav {
    -webkit-align-items: center;
            align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0.5rem 1.25rem rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-md .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-md .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-md .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-md .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-md .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-md .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-md .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-md .u-header__nav-item-user-name:hover {
    color: #21c87a;
  }
  .navbar-expand-md .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-md .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-md .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 1.5625rem rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-md .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-md .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-md .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-lg .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-lg .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-lg .u-header__hamburger {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-brand-wrapper {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-collapse {
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-lg .u-header__secondary-nav {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 3;
            order: 3;
  }
  .navbar-expand-lg .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: .9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-lg .u-header__navbar-nav {
    -webkit-align-items: center;
            align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0.5rem 1.25rem rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-lg .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-lg .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-lg .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-lg .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-lg .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-lg .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-lg .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-lg .u-header__nav-item-user-name:hover {
    color: #21c87a;
  }
  .navbar-expand-lg .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-lg .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-lg .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 1.5625rem rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-lg .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-lg .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-lg .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  .u-header--bordered .navbar-expand-xl .u-header__navbar-brand {
    border-right: 1px solid #e3e6f0;
    margin-right: 1.375rem;
  }
  .u-header--bordered .navbar-expand-xl .u-header__navbar-v-divider {
    border-left: 0.0625rem solid #e3e6f0;
    margin-left: 1.375rem;
  }
  .u-header--modern .navbar-expand-xl .u-header__hamburger {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-brand-wrapper {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-collapse {
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-xl .u-header__secondary-nav {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: none;
    -webkit-order: 3;
            order: 3;
  }
  .navbar-expand-xl .u-header__navbar-brand {
    padding-top: 1rem;
    padding-bottom: .9375rem;
    padding-right: 1rem;
  }
  .navbar-expand-xl .u-header__navbar-nav {
    -webkit-align-items: center;
            align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: auto;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu {
    border: 1px solid #e3e6f0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 0.5rem 1.25rem rgba(100, 111, 121, 0.1);
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .u-header__sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-list-item {
    padding: 0;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-offset {
    margin-top: -1.0625rem;
  }
  .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand-xl .u-header__mega-menu-list {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    list-style: none;
  }
  .navbar-expand-xl .u-header__mega-menu-col {
    padding-left: 2rem;
  }
  .navbar-expand-xl .u-header__mega-menu-col:first-child {
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__mega-menu-col:not(:last-child) {
    border-right: 1px solid #e3e6f0;
  }
  .navbar-expand-xl .u-header__mega-menu-wrapper-v1 {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  .navbar-expand-xl .u-header__nav-item-btn {
    padding-left: 1rem;
  }
  .navbar-expand-xl .u-header__nav-item-user {
    background-color: #f5f8fb;
    border-left: 1px solid #e3e6f0;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1rem;
  }
  .navbar-expand-xl .u-header__nav-item-user-name {
    color: #646f79;
  }
  .navbar-expand-xl .u-header__nav-item-user-name:hover {
    color: #21c87a;
  }
  .navbar-expand-xl .u-header__nav-item-user + .u-header__navbar-v-divider {
    margin-left: 0;
  }
  .navbar-expand-xl .u-header__navbar-v-divider {
    padding-left: 0.875rem;
  }
  .navbar-expand-xl .u-header__search {
    position: absolute;
    right: 0;
    z-index: 102;
    width: 23.125rem;
    background-color: #fff;
    border: 1px solid #e3e6f0;
    box-shadow: 0 0 1.5625rem rgba(21, 27, 38, 0.1);
    padding: 1rem;
    border-radius: 0;
    margin-top: 1.3125rem;
    transition: none;
  }
  .navbar-expand-xl .u-header__search.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .u-header--left-aligned-nav .navbar-expand-xl .u-header__navbar-nav {
    width: 100%;
    margin-left: 0;
  }
  .u-header--left-aligned-nav .navbar-expand-xl .u-header__nav-item-btn {
    margin-left: auto;
  }
}

@media (max-width: 575.98px) {
  .u-header--modern .navbar-expand-sm.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-sm .u-header__hamburger {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-brand-wrapper {
    text-align: center;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-sm .u-header__navbar-collapse {
    -webkit-order: 4;
            order: 4;
  }
  .u-header--modern .navbar-expand-sm .u-header__secondary-nav {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 2;
            order: 2;
  }
  .navbar-expand-sm.u-header__navbar {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }
  .navbar-expand-sm .u-header__nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .navbar-expand-sm .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-sm .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-sm .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-sm .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-sm .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-sm .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-sm .u-header__mega-menu-list {
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-sm .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-sm .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-sm .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-sm .u-header__search-toggle {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .u-header--modern .navbar-expand-md.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-md .u-header__hamburger {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-brand-wrapper {
    text-align: center;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-md .u-header__navbar-collapse {
    -webkit-order: 4;
            order: 4;
  }
  .u-header--modern .navbar-expand-md .u-header__secondary-nav {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 2;
            order: 2;
  }
  .navbar-expand-md.u-header__navbar {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }
  .navbar-expand-md .u-header__nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .navbar-expand-md .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-md .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-md .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-md .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-md .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-md .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-md .u-header__mega-menu-list {
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-md .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-md .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-md .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-md .u-header__search-toggle {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .u-header--modern .navbar-expand-lg.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-lg .u-header__hamburger {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-brand-wrapper {
    text-align: center;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-lg .u-header__navbar-collapse {
    -webkit-order: 4;
            order: 4;
  }
  .u-header--modern .navbar-expand-lg .u-header__secondary-nav {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 2;
            order: 2;
  }
  .navbar-expand-lg.u-header__navbar {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }
  .navbar-expand-lg .u-header__nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .navbar-expand-lg .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-lg .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-lg .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-lg .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-lg .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-lg .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-lg .u-header__mega-menu-list {
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-lg .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-lg .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-lg .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-lg .u-header__search-toggle {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .u-header--modern .navbar-expand-xl.u-header__navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .u-header--modern .navbar-expand-xl .u-header__hamburger {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 1;
            order: 1;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-brand-wrapper {
    text-align: center;
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
    -webkit-order: 2;
            order: 2;
  }
  .u-header--modern .navbar-expand-xl .u-header__navbar-collapse {
    -webkit-order: 4;
            order: 4;
  }
  .u-header--modern .navbar-expand-xl .u-header__secondary-nav {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
    -webkit-order: 2;
            order: 2;
  }
  .navbar-expand-xl.u-header__navbar {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
  }
  .navbar-expand-xl .u-header__nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .navbar-expand-xl .u-header__nav-link-icon {
    margin-left: auto;
  }
  .navbar-expand-xl .hs-mega-menu-opened .u-header__nav-link-icon,
  .navbar-expand-xl .hs-sub-menu-opened .u-header__nav-link-icon {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
  .navbar-expand-xl .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
    transition: 0.3s;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .navbar-expand-xl .u-header__sub-menu {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    -webkit-animation: none;
            animation: none;
  }
  .navbar-expand-xl .u-header__sub-menu-list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand-xl .u-header__mega-menu-list {
    padding-top: .375rem;
    padding-bottom: .375rem;
    padding-left: 0.375rem;
  }
  .navbar-expand-xl .u-header__nav-item-btn {
    padding-top: 1rem;
    padding-bottom: 1.375rem;
  }
  .navbar-expand-xl .u-header__navbar-v-divider {
    border-top: 0.0625rem solid #e3e6f0;
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-expand-xl .u-header__search {
    -webkit-animation-duration: 0ms !important;
            animation-duration: 0ms !important;
  }
  .navbar-expand-xl .u-header__search-toggle {
    display: none;
  }
}

.u-header--modern .navbar-expand.u-header__navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.u-header--modern .navbar-expand .u-header__hamburger {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  -webkit-order: 1;
          order: 1;
}

.u-header--modern .navbar-expand .u-header__navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
}

.u-header--modern .navbar-expand .u-header__navbar-brand-wrapper {
  text-align: center;
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
  -webkit-order: 2;
          order: 2;
}

.u-header--modern .navbar-expand .u-header__navbar-collapse {
  -webkit-order: 4;
          order: 4;
}

.u-header--modern .navbar-expand .u-header__secondary-nav {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
  -webkit-order: 2;
          order: 2;
}

.navbar-expand.u-header__navbar {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
}

.navbar-expand .u-header__nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.navbar-expand .u-header__nav-link-icon {
  margin-left: auto;
}

.navbar-expand .hs-mega-menu-opened .u-header__nav-link-icon,
.navbar-expand .hs-sub-menu-opened .u-header__nav-link-icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.navbar-expand .hs-sub-menu-opened > .u-header__sub-menu-nav-link .u-header__sub-menu-nav-link-icon {
  transition: 0.3s;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.navbar-expand .u-header__sub-menu {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
  -webkit-animation: none;
          animation: none;
}

.navbar-expand .u-header__sub-menu-list-item {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-expand .u-header__mega-menu-list {
  padding-top: .375rem;
  padding-bottom: .375rem;
  padding-left: 0.375rem;
}

.navbar-expand .u-header__nav-item-btn {
  padding-top: 1rem;
  padding-bottom: 1.375rem;
}

.navbar-expand .u-header__navbar-v-divider {
  border-top: 0.0625rem solid #e3e6f0;
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .u-header__search {
  -webkit-animation-duration: 0ms !important;
          animation-duration: 0ms !important;
}

.navbar-expand .u-header__search-toggle {
  display: none;
}

@media (min-width: 576px) {
  .u-header__navbar-brand-default {
    display: inline-block;
  }
  .u-header__navbar-brand-mobile {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .u-header__navbar-brand-default, .u-header__navbar-brand-on-scroll {
    display: none;
  }
  .u-header__navbar-brand-mobile {
    display: inline-block;
  }
}

/*------------------------------------
  Header Navbar Background Colors
------------------------------------*/
.u-header--navbar-bg .u-header__section {
  border-color: transparent;
}

.u-header--navbar-bg.u-header--bordered .u-header__section,
.u-header--navbar-bg.u-header--bordered .u-header__navbar-brand,
.u-header--navbar-bg.u-header--bordered .u-header__nav-item-user,
.u-header--navbar-bg.u-header--bordered .u-header__navbar-v-divider {
  border-color: rgba(255, 255, 255, 0.15);
}

.u-header--navbar-bg .u-header__nav-item:hover .u-header__nav-link {
  color: #fff;
}

.u-header--navbar-bg .u-header__nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-header__nav-link:hover {
  color: #fff;
}

.u-header--navbar-bg [class*="btn-text-"] {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg [class*="btn-text-"]:focus, .u-header--navbar-bg [class*="btn-text-"]:hover {
  color: #fff;
}

.u-header--navbar-bg .u-header__nav-item-user {
  background-color: rgba(255, 255, 255, 0.075);
}

.u-header--navbar-bg .u-header__nav-item-user-name {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-header__nav-item-user-name:hover {
  color: #fff;
}

.u-header--navbar-bg .u-hamburger {
  color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-hamburger:hover {
  color: #fff;
}

.u-header--navbar-bg .u-hamburger__inner, .u-header--navbar-bg .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::before, .u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::after {
  background-color: #fff;
}

.u-header--navbar-bg .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Header Submenu Background Colors
------------------------------------*/
.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu {
  background-color: #151b26;
  border-color: rgba(255, 255, 255, 0.15);
}

.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu-title {
  color: rgba(255, 255, 255, 0.9);
}

.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu-nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.u-header--sub-menu-dark-bg .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
  color: #fff;
}

.u-header--sub-menu-dark-bg .u-header__mega-menu-col:not(:last-child) {
  border-right-color: rgba(255, 255, 255, 0.15);
}

.u-header--sub-menu-dark-bg .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.15);
}

@media (min-width: 576px) {
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-sm .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-sm .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-sm .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

@media (min-width: 768px) {
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-md .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-md .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-md .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

@media (min-width: 992px) {
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-lg .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-lg .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-lg .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

@media (min-width: 1200px) {
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu {
    background-color: #151b26;
    border-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu-title {
    color: rgba(255, 255, 255, 0.9);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  .u-header--sub-menu-dark-bg-xl .u-header__navbar-nav .u-header__sub-menu-nav-link:hover {
    color: #fff;
  }
  .u-header--sub-menu-dark-bg-xl .u-header__mega-menu-col:not(:last-child) {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
  .u-header--sub-menu-dark-bg-xl .dropdown-divider {
    border-color: rgba(255, 255, 255, 0.15);
  }
}

/*------------------------------------
  Header Floating
------------------------------------*/
.u-header--floating:not([class*="u-header--floating-"]) {
  position: absolute;
  right: 0;
  left: 0;
  margin-top: 2rem;
}

[class*="u-header--floating"].js-header-fix-moment {
  background-color: #fff;
  margin-top: 0;
}

.u-header--floating.js-header-fix-moment {
  position: fixed;
  border-bottom: 1px solid #e3e6f0;
}

.u-header--floating.js-header-fix-moment .u-header--floating__inner {
  border-radius: 0;
}

.u-header--floating__inner {
  border-bottom: 0;
  border-radius: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Small devices */
@media (min-width: 576px) {
  .u-header--floating-sm {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-sm__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-sm.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-sm.js-header-fix-moment .u-header--floating-sm__inner {
    border-radius: 0;
  }
}

@media (max-width: 575.98px) {
  .u-header--floating-sm {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-sm__inner {
    border-bottom: 0;
  }
}

/* Medium devices */
@media (min-width: 768px) {
  .u-header--floating-md {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-md__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-md.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-md.js-header-fix-moment .u-header--floating-md__inner {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .u-header--floating-md {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-md__inner {
    border-bottom: 0;
  }
}

/* Large devices */
@media (min-width: 992px) {
  .u-header--floating-lg {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-lg__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-lg.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-lg.js-header-fix-moment .u-header--floating-lg__inner {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .u-header--floating-lg {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-lg__inner {
    border-bottom: 0;
  }
}

/* Extra Large devices */
@media (min-width: 1200px) {
  .u-header--floating-xl {
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 2rem;
  }
  .u-header--floating-xl__inner {
    border-bottom: 0;
    border-radius: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .u-header--floating-xl.js-header-fix-moment {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-xl.js-header-fix-moment .u-header--floating-xl__inner {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .u-header--floating-xl {
    border-bottom: 1px solid #e3e6f0;
  }
  .u-header--floating-xl__inner {
    border-bottom: 0;
  }
}

.u-header--floating__inner,
.u-header--floating-sm__inner,
.u-header--floating-md__inner,
.u-header--floating-lg__inner,
.u-header--floating-xl__inner {
  max-height: 90vh;
}

.u-header--floating__inner.mCS_destroyed,
.u-header--floating-sm__inner.mCS_destroyed,
.u-header--floating-md__inner.mCS_destroyed,
.u-header--floating-lg__inner.mCS_destroyed,
.u-header--floating-xl__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--floating"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 3.75rem 1.5rem;
}

[class*="u-header--floating"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 70%;
}

[class*="u-header--floating"] .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent !important;
}

/*------------------------------------
  Absolute Positions
------------------------------------*/
.u-header--abs-top, .u-header--abs-bottom, .u-header--abs-top-2nd-screen {
  position: absolute;
}

.u-header--abs-top {
  top: 0;
  bottom: auto;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) {
  top: 100%;
  bottom: auto;
}

.u-header--abs-top-2nd-screen[data-header-fix-effect] {
  transition: none;
}

.u-header--abs-bottom:not(.js-header-fix-moment) {
  top: auto;
  bottom: 0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom:not(.js-header-fix-moment) .u-header__section {
  border-top: 1px solid #e3e6f0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-unfold,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__search,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) .u-unfold,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__search,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
.u-header--abs-bottom:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
  bottom: 0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom:not(.js-header-fix-moment) .u-unfold {
  margin-bottom: 0;
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
}

.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom:not(.js-header-fix-moment) .fadeInUp {
  -webkit-animation-name: absoluteFadeInUp;
          animation-name: absoluteFadeInUp;
}

@media (min-width: 576px) {
  .u-header--abs-top-sm, .u-header--abs-bottom-sm, .u-header--abs-top-2nd-screen-sm {
    position: absolute;
  }
  .u-header--abs-top-sm {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-sm[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-sm:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-sm:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-sm:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

@media (min-width: 768px) {
  .u-header--abs-top-md, .u-header--abs-bottom-md, .u-header--abs-top-2nd-screen-md {
    position: absolute;
  }
  .u-header--abs-top-md {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-md[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-md:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-md:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-md:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-md:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

@media (min-width: 992px) {
  .u-header--abs-top-lg, .u-header--abs-bottom-lg, .u-header--abs-top-2nd-screen-lg {
    position: absolute;
  }
  .u-header--abs-top-lg {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-lg[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-lg:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-lg:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-lg:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--abs-top-xl, .u-header--abs-bottom-xl, .u-header--abs-top-2nd-screen-xl {
    position: absolute;
  }
  .u-header--abs-top-xl {
    top: 0;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
  }
  .u-header--abs-top-2nd-screen-xl[data-header-fix-effect] {
    transition: none;
  }
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__section, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__section {
    border-top: 1px solid #e3e6f0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__sub-menu, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-unfold,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__search,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-unfold .u-header__sub-menu,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__search .u-header__sub-menu,
  .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    bottom: 0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .u-unfold, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .u-unfold {
    margin-bottom: 0;
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom-xl:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--abs-top-2nd-screen-xl:not(.js-header-fix-moment) .fadeInUp, .u-header--abs-bottom-xl:not(.js-header-fix-moment) .fadeInUp {
    -webkit-animation-name: absoluteFadeInUp;
            animation-name: absoluteFadeInUp;
  }
}

.u-header--abs__inner {
  max-height: 90vh;
}

.u-header--abs__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--abs"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 3.75rem 1.5rem;
}

[class*="u-header--abs"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 70%;
}

[class*="u-header--abs"] .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent !important;
}

/* Disable box-shadow on mobile device */
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu, .u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

.u-header--abs-top-2nd-screen-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--abs-top-2nd-screen-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--abs-bottom-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--abs-bottom-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none !important;
}

@-webkit-keyframes absoluteFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

@keyframes absoluteFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

/*------------------------------------
  Header Sidebar
------------------------------------*/
.u-header-sidebar__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 10.625rem;
}

.u-header-sidebar__content {
  padding: 3rem;
}

.u-header-sidebar__navbar-brand {
  display: block;
  width: 6.25rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
}

.u-header-sidebar__footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem 3rem;
}

.u-header-sidebar__footer-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: .875rem;
}

.u-header-sidebar__footer-link:hover {
  color: #fff;
}

/*------------------------------------
  Sticky Positions
------------------------------------*/
.u-header--sticky-top, .u-header--sticky-bottom {
  position: fixed;
}

.u-header--sticky-top {
  bottom: auto;
  top: 0;
}

.u-header--sticky-bottom {
  top: auto;
  bottom: 0;
}

.u-header--sticky-bottom .u-header__section {
  border-top: 1px solid #e3e6f0;
  border-bottom: 0;
}

.u-header--sticky-bottom .u-unfold,
.u-header--sticky-bottom .u-header__search,
.u-header--sticky-bottom .u-header__sub-menu {
  top: auto;
  bottom: 100%;
}

.u-header--sticky-bottom .u-unfold {
  margin-bottom: 7px;
}

.u-header--sticky-bottom .u-header__navbar-nav .u-header__sub-menu {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
}

.u-header--sticky-bottom .fadeInUp {
  -webkit-animation-name: stickyFadeInUp;
          animation-name: stickyFadeInUp;
}

@media (min-width: 576px) {
  .u-header--sticky-top-sm, .u-header--sticky-bottom-sm {
    position: fixed;
  }
  .u-header--sticky-top-sm {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-sm {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-sm .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-sm .u-unfold,
  .u-header--sticky-bottom-sm .u-header__search,
  .u-header--sticky-bottom-sm .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-sm .u-unfold {
    margin-bottom: 7px;
  }
  .u-header--sticky-bottom-sm .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-sm .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

@media (min-width: 768px) {
  .u-header--sticky-top-md, .u-header--sticky-bottom-md {
    position: fixed;
  }
  .u-header--sticky-top-md {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-md {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-md .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-md .u-unfold,
  .u-header--sticky-bottom-md .u-header__search,
  .u-header--sticky-bottom-md .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-md .u-unfold {
    margin-bottom: 7px;
  }
  .u-header--sticky-bottom-md .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-md .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

@media (min-width: 992px) {
  .u-header--sticky-top-lg, .u-header--sticky-bottom-lg {
    position: fixed;
  }
  .u-header--sticky-top-lg {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-lg {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-lg .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-lg .u-unfold,
  .u-header--sticky-bottom-lg .u-header__search,
  .u-header--sticky-bottom-lg .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-lg .u-unfold {
    margin-bottom: 7px;
  }
  .u-header--sticky-bottom-lg .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-lg .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

@media (min-width: 1200px) {
  .u-header--sticky-top-xl, .u-header--sticky-bottom-xl {
    position: fixed;
  }
  .u-header--sticky-top-xl {
    bottom: auto;
    top: 0;
  }
  .u-header--sticky-bottom-xl {
    top: auto;
    bottom: 0;
  }
  .u-header--sticky-bottom-xl .u-header__section {
    border-top: 1px solid #e3e6f0;
    border-bottom: 0;
  }
  .u-header--sticky-bottom-xl .u-unfold,
  .u-header--sticky-bottom-xl .u-header__search,
  .u-header--sticky-bottom-xl .u-header__sub-menu {
    top: auto;
    bottom: 100%;
  }
  .u-header--sticky-bottom-xl .u-unfold {
    margin-bottom: 7px;
  }
  .u-header--sticky-bottom-xl .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -0.5rem 1.25rem rgba(21, 27, 38, 0.1);
  }
  .u-header--sticky-bottom-xl .fadeInUp {
    -webkit-animation-name: stickyFadeInUp;
            animation-name: stickyFadeInUp;
  }
}

.u-header--sticky__inner {
  max-height: 90vh;
}

.u-header--sticky__inner.mCS_destroyed {
  position: static !important;
}

[class*="u-header--sticky"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin: 3.75rem 1.5rem;
}

[class*="u-header--sticky"] .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 70%;
}

[class*="u-header--sticky"] .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent !important;
}

/* Disable box-shadow on mobile device */
.u-header--sticky-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none;
}

.u-header--sticky-top-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--sticky-top-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--sticky-bottom-sm .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu,
.u-header--sticky-bottom-md .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
  box-shadow: none !important;
}

@-webkit-keyframes stickyFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

@keyframes stickyFadeInUp {
  from {
    -webkit-transform: translate3d(0, -25%, 0);
            transform: translate3d(0, -25%, 0);
  }
}

/*------------------------------------
  Header Center Aligned
------------------------------------*/
.u-header--center-aligned__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 1rem;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  padding-top: .5rem;
}

.u-header--center-aligned-sm__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 576px) {
  .u-header--center-aligned-sm__inner {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
}

.u-header--center-aligned-md__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 768px) {
  .u-header--center-aligned-md__inner {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
}

.u-header--center-aligned-lg__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 992px) {
  .u-header--center-aligned-lg__inner {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
}

.u-header--center-aligned-xl__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 1rem;
}

@media (min-width: 1200px) {
  .u-header--center-aligned-xl__inner {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    padding-top: .5rem;
  }
}

@media (max-width: 575.98px) {
  .u-header--center-aligned .navbar-expand-sm.u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 767.98px) {
  .u-header--center-aligned .navbar-expand-md.u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 991.98px) {
  .u-header--center-aligned .navbar-expand-lg.u-header__navbar {
    padding-top: 0;
  }
}

@media (max-width: 1199.98px) {
  .u-header--center-aligned .navbar-expand-xl.u-header__navbar {
    padding-top: 0;
  }
}

.u-header--center-aligned .navbar-expand.u-header__navbar {
  padding-top: 0;
}

/*------------------------------------
  Hamburgers
------------------------------------*/
.u-hamburger {
  font-size: inherit;
  line-height: 0;
  padding: 0.125rem;
}

.u-hamburger:hover .u-hamburger__inner, .u-hamburger:hover .u-hamburger__inner::before, .u-hamburger:hover .u-hamburger__inner::after {
  background-color: #21c87a;
}

.u-hamburger__box {
  width: 1.375rem;
  height: 0.7rem;
  display: inline-block;
  position: relative;
}

.u-hamburger__inner {
  display: block;
  top: 0.08333rem;
  margin-top: -0.08333rem;
}

.u-hamburger__inner, .u-hamburger__inner::before, .u-hamburger__inner::after {
  width: 1.375rem;
  height: 0.15rem;
  background-color: #8c98a4;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::before, .u-hamburger__inner::after {
  content: "";
  display: block;
}

.u-hamburger__inner::before {
  top: 0.275rem;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.u-hamburger__inner::after {
  top: 0.55rem;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner,
.u-hamburger .is-active .u-hamburger__inner {
  -webkit-transform: translate3d(0, 0.275rem, 0) rotate(45deg);
          transform: translate3d(0, 0.275rem, 0) rotate(45deg);
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::before,
.u-hamburger .is-active .u-hamburger__inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-0.19643rem, -0.125rem, 0);
          transform: rotate(-45deg) translate3d(-0.19643rem, -0.125rem, 0);
  opacity: 0;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::after,
.u-hamburger .is-active .u-hamburger__inner::after {
  -webkit-transform: translate3d(0, -0.55rem, 0) rotate(-90deg);
          transform: translate3d(0, -0.55rem, 0) rotate(-90deg);
}

.u-hamburger--white .u-hamburger__inner, .u-hamburger--white .u-hamburger__inner::before, .u-hamburger--white .u-hamburger__inner::after {
  background-color: rgba(255, 255, 255, 0.8);
}

.u-hamburger--white:hover .u-hamburger__inner, .u-hamburger--white:hover .u-hamburger__inner::before, .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: #fff;
}

.js-header-fix-moment .u-hamburger--white .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner::after {
  background-color: #8c98a4;
}

.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::before, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::after {
  background-color: #21c87a;
}

/*------------------------------------
  Unfold (Drop Down)
------------------------------------*/
/* Disable content revealing on page load */
.u-unfold[aria-labelledby] {
  opacity: 0;
}

.u-unfold[aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-unfold[aria-labelledby].u-unfold--css-animation, .u-unfold[aria-labelledby].u-unfold--jquery-slide {
  opacity: 1;
}

.u-unfold.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-unfold {
  position: absolute;
  z-index: 99;
  min-width: 8.4375rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.9375rem rgba(100, 111, 121, 0.1);
  padding: 1rem 1.5rem;
  border: none;
  margin-bottom: 0;
}

.u-unfold-wrapper {
  color: #646f79;
  font-size: 0.875rem;
}

.u-unfold-wrapper:hover {
  color: #151b26;
}

.u-unfold-wrapper--light {
  color: #8f95a0;
}

.u-unfold-wrapper--light:hover {
  color: #fff;
}

.u-unfold__icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 1.0625rem;
  margin-right: .5rem;
}

.u-unfold__icon-pointer {
  font-size: 0.625rem;
  vertical-align: middle;
  margin-left: .25rem;
}

/*------------------------------------
  Unfold (Drop Down) - Language
------------------------------------*/
.u-unfold--language {
  min-width: 18.5rem;
  padding: 0;
  margin-bottom: 3.5rem;
}

.u-unfold--language-btn {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.u-unfold--language-btn:hover, .u-unfold--language-btn:focus {
  color: #fff !important;
}

.u-unfold--language__link {
  display: block;
  background-color: #f5f8fb;
}

.active .u-unfold--language-icon-pointer {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

@media (min-width: 576px) {
  .u-unfold--language {
    min-width: 22.5rem;
  }
}

/*------------------------------------
  Unfold (Drop Down) - Cart
------------------------------------*/
.u-unfold--cart {
  margin-top: 1.3125rem;
}

/*------------------------------------
  Alert
------------------------------------*/
.address-pin {
  position: relative;
  padding-left: 2.6rem;
  border-bottom: 1px solid #f5f8fb;
}

.address-pin__pin {
  position: absolute;
  top: 3px;
  left: 0;
}

/*------------------------------------
  Alert
------------------------------------*/
.alert-text {
  color: inherit;
}

/*------------------------------------
  Avatar
------------------------------------*/
.u-avatar {
  width: 2rem;
  height: 2rem;
}

.u-sm-avatar {
  width: 2.75rem;
  height: 2.75rem;
}

.u-md-avatar {
  width: 4rem;
  height: 4rem;
}

.u-lg-avatar {
  width: 6rem;
  height: 6rem;
}

/*------------------------------------
  Avatar Bordered
------------------------------------*/
.u-sm-avatar--bordered {
  border: 2px solid #fff;
}

.u-md-avatar--bordered {
  border: 4px solid #fff;
}

.u-xl-avatar--bordered {
  border: 7px solid #fff;
}

/*------------------------------------
  Badges
------------------------------------*/
[class*="badge-"] {
  z-index: 3;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 1.7;
}

.badge-xs {
  width: 1.125rem;
  height: 1.125rem;
  font-size: 0.625rem;
}

.badge-sm {
  width: 1.625rem;
  height: 1.625rem;
  font-size: 0.875rem;
}

.badge-sm.badge-icon {
  line-height: 1.5;
}

.badge-pos {
  position: absolute;
}

/* Positions */
.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.badge-pos--top-left {
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.badge-pos--bottom-left {
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

.badge-pos--bottom-right {
  bottom: 0;
  right: 0;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
}

.badge-pos--bottom-right-minus-1 {
  bottom: -.25rem;
  right: -.25rem;
}

.badge-primary-soft {
  color: #21c87a;
  background-color: rgba(33, 200, 122, 0.1);
}

/*------------------------------------
  Buttons
------------------------------------*/
.btn {
  font-weight: 400;
  cursor: pointer;
}

.btn-wide {
  min-width: 10rem;
}

/*------------------------------------
  Buttons Bootstrap Style
------------------------------------*/
/* facebook */
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}

.btn-facebook:focus, .btn-facebook.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

.btn-facebook.disabled, .btn-facebook:disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2d4373;
  border-color: #293e6a;
}

.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 114, 167, 0.5);
}

/* google */
.btn-google {
  color: #fff;
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:hover {
  color: #fff;
  background-color: #b33628;
  border-color: #a93325;
}

.btn-google:focus, .btn-google.focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 94, 79, 0.5);
}

.btn-google.disabled, .btn-google:disabled {
  color: #fff;
  background-color: #d14130;
  border-color: #d14130;
}

.btn-google:not(:disabled):not(.disabled):active, .btn-google:not(:disabled):not(.disabled).active,
.show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #a93325;
  border-color: #9e3023;
}

.btn-google:not(:disabled):not(.disabled):active:focus, .btn-google:not(:disabled):not(.disabled).active:focus,
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 94, 79, 0.5);
}

/* twitter */
.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}

.btn-twitter:focus, .btn-twitter.focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

.btn-twitter.disabled, .btn-twitter:disabled {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #0c85d0;
  border-color: #0b7ec4;
}

.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 175, 244, 0.5);
}

/* instagram */
.btn-instagram {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:hover {
  color: #fff;
  background-color: #345e80;
  border-color: #305777;
}

.btn-instagram:focus, .btn-instagram.focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}

.btn-instagram.disabled, .btn-instagram:disabled {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #305777;
  border-color: #2d516e;
}

.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(92, 135, 170, 0.5);
}

/* github */
.btn-github {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:hover {
  color: #fff;
  background-color: #131619;
  border-color: #0e1011;
}

.btn-github:focus, .btn-github.focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

.btn-github.disabled, .btn-github:disabled {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e;
}

.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #0e1011;
  border-color: #08090a;
}

.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 73, 77, 0.5);
}

/*------------------------------------
  Button Sizes
------------------------------------*/
.btn-xs {
  font-size: 0.6875rem;
  padding: 0.4375rem 0.9375rem;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 0.25rem;
}

/* Icons */
.btn-icon {
  position: relative;
  line-height: 0;
  font-size: 1rem;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0;
}

.btn-icon__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

/*------------------------------------
  Text Buttons Styles
------------------------------------*/
/* primary text button */
.btn-text-primary {
  color: #21c87a;
  background: transparent;
}

.btn-text-primary:hover, .btn-text-primary:focus, .btn-text-primary:active {
  color: #151b26;
}

/* secondary text button */
.btn-text-secondary {
  color: #646f79;
  background: transparent;
}

.btn-text-secondary:hover, .btn-text-secondary:focus, .btn-text-secondary:active {
  color: #151b26;
}

/* success text button */
.btn-text-success {
  color: #21c87a;
  background: transparent;
}

.btn-text-success:hover, .btn-text-success:focus, .btn-text-success:active {
  color: #151b26;
}

/* info text button */
.btn-text-info {
  color: #00dffc;
  background: transparent;
}

.btn-text-info:hover, .btn-text-info:focus, .btn-text-info:active {
  color: #151b26;
}

/* warning text button */
.btn-text-warning {
  color: #ffbc42;
  background: transparent;
}

.btn-text-warning:hover, .btn-text-warning:focus, .btn-text-warning:active {
  color: #151b26;
}

/* danger text button */
.btn-text-danger {
  color: #da4b48;
  background: transparent;
}

.btn-text-danger:hover, .btn-text-danger:focus, .btn-text-danger:active {
  color: #151b26;
}

/* light text button */
.btn-text-light {
  color: #f5f8fb;
  background: transparent;
}

.btn-text-light:hover, .btn-text-light:focus, .btn-text-light:active {
  color: #d4d7dd;
}

/* dark text button */
.btn-text-dark {
  color: #151b26;
  background: transparent;
}

.btn-text-dark:hover, .btn-text-dark:focus, .btn-text-dark:active {
  color: #151b26;
}

/* purple text button */
.btn-text-purple {
  color: #796eff;
  background: transparent;
}

.btn-text-purple:hover, .btn-text-purple:focus, .btn-text-purple:active {
  color: #151b26;
}

/* blue text button */
.btn-text-blue {
  color: #1c6be1;
  background: transparent;
}

.btn-text-blue:hover, .btn-text-blue:focus, .btn-text-blue:active {
  color: #151b26;
}

/* primary-soft text button */
.btn-text-primary-soft {
  color: rgba(33, 200, 122, 0.1);
  background: transparent;
}

.btn-text-primary-soft:hover, .btn-text-primary-soft:focus, .btn-text-primary-soft:active {
  color: #151b26;
}

/* white-soft text button */
.btn-text-white-soft {
  color: rgba(255, 255, 255, 0.1);
  background: transparent;
}

.btn-text-white-soft:hover, .btn-text-white-soft:focus, .btn-text-white-soft:active {
  color: #151b26;
}

/* dark-soft text button */
.btn-text-dark-soft {
  color: rgba(21, 27, 38, 0.4);
  background: transparent;
}

.btn-text-dark-soft:hover, .btn-text-dark-soft:focus, .btn-text-dark-soft:active {
  color: #151b26;
}

/*------------------------------------
  Dropdown
------------------------------------*/
.dropdown-menu {
  font-size: 0.875rem;
  padding: 1rem 1.5rem;
  box-shadow: 0 0.125rem 0.9375rem rgba(100, 111, 121, 0.1);
}

/*------------------------------------
  Divider
------------------------------------*/
.u-divider {
  position: relative;
  display: inline-block;
}

.u-divider::before, .u-divider::after {
  position: absolute;
  top: 50%;
  background-color: #d4d7dd;
  content: "";
}

.u-divider::before {
  right: 100%;
}

.u-divider::after {
  left: 100%;
}

.u-divider-wrapper {
  overflow: hidden;
}

/*------------------------------------
  Divider Sizes
------------------------------------*/
/* Extra Small */
.u-divider--xs {
  font-size: 0.75rem;
}

.u-divider--xs::before, .u-divider--xs::after {
  width: 999px;
  height: 0.0625rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.u-divider--xs::before {
  margin-right: .625rem;
}

.u-divider--xs::after {
  margin-left: .625rem;
}

/*------------------------------------
  Divider Styles
------------------------------------*/
/* Text */
.u-divider--text {
  color: #8c98a4;
}

/*------------------------------------
  Icon
------------------------------------*/
.u-icon {
  position: relative;
  line-height: 0.7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: 0.3s ease-in-out;
}

.u-icon__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-icon--shadow {
  box-shadow: 0 0.25rem 0.5rem 0 #e3e6f0;
}

/*------------------------------------
  Icon Default Styles
------------------------------------*/
/* primary */
.u-icon--primary {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.u-icon--primary[href]:hover {
  color: #fff;
  background-color: #1ca766;
  border-color: #1a9c5f;
}

.u-icon--primary.disabled, .u-icon--primary:disabled {
  background-color: #21c87a;
  border-color: #21c87a;
}

/* secondary */
.u-icon--secondary {
  color: #fff;
  background-color: #646f79;
  border-color: #646f79;
}

.u-icon--secondary[href]:hover {
  color: #fff;
  background-color: #535c64;
  border-color: #4d555d;
}

.u-icon--secondary.disabled, .u-icon--secondary:disabled {
  background-color: #646f79;
  border-color: #646f79;
}

/* success */
.u-icon--success {
  color: #fff;
  background-color: #21c87a;
  border-color: #21c87a;
}

.u-icon--success[href]:hover {
  color: #fff;
  background-color: #1ca766;
  border-color: #1a9c5f;
}

.u-icon--success.disabled, .u-icon--success:disabled {
  background-color: #21c87a;
  border-color: #21c87a;
}

/* info */
.u-icon--info {
  color: #212529;
  background-color: #00dffc;
  border-color: #00dffc;
}

.u-icon--info[href]:hover {
  color: #fff;
  background-color: #00bdd6;
  border-color: #00b2c9;
}

.u-icon--info.disabled, .u-icon--info:disabled {
  background-color: #00dffc;
  border-color: #00dffc;
}

/* warning */
.u-icon--warning {
  color: #212529;
  background-color: #ffbc42;
  border-color: #ffbc42;
}

.u-icon--warning[href]:hover {
  color: #212529;
  background-color: #ffae1c;
  border-color: #ffaa0f;
}

.u-icon--warning.disabled, .u-icon--warning:disabled {
  background-color: #ffbc42;
  border-color: #ffbc42;
}

/* danger */
.u-icon--danger {
  color: #fff;
  background-color: #da4b48;
  border-color: #da4b48;
}

.u-icon--danger[href]:hover {
  color: #fff;
  background-color: #d12e2a;
  border-color: #c72b28;
}

.u-icon--danger.disabled, .u-icon--danger:disabled {
  background-color: #da4b48;
  border-color: #da4b48;
}

/* light */
.u-icon--light {
  color: #212529;
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

.u-icon--light[href]:hover {
  color: #212529;
  background-color: #dae5f0;
  border-color: #d1dfec;
}

.u-icon--light.disabled, .u-icon--light:disabled {
  background-color: #f5f8fb;
  border-color: #f5f8fb;
}

/* dark */
.u-icon--dark {
  color: #fff;
  background-color: #151b26;
  border-color: #151b26;
}

.u-icon--dark[href]:hover {
  color: #fff;
  background-color: #07090d;
  border-color: #030405;
}

.u-icon--dark.disabled, .u-icon--dark:disabled {
  background-color: #151b26;
  border-color: #151b26;
}

/* purple */
.u-icon--purple {
  color: #fff;
  background-color: #796eff;
  border-color: #796eff;
}

.u-icon--purple[href]:hover {
  color: #fff;
  background-color: #5648ff;
  border-color: #4a3bff;
}

.u-icon--purple.disabled, .u-icon--purple:disabled {
  background-color: #796eff;
  border-color: #796eff;
}

/* blue */
.u-icon--blue {
  color: #fff;
  background-color: #1c6be1;
  border-color: #1c6be1;
}

.u-icon--blue[href]:hover {
  color: #fff;
  background-color: #185bbf;
  border-color: #1655b4;
}

.u-icon--blue.disabled, .u-icon--blue:disabled {
  background-color: #1c6be1;
  border-color: #1c6be1;
}

/* primary-soft */
.u-icon--primary-soft {
  color: #fff;
  background-color: rgba(33, 200, 122, 0.1);
  border-color: rgba(33, 200, 122, 0.1);
}

.u-icon--primary-soft[href]:hover {
  color: #fff;
  background-color: rgba(28, 167, 102, 0.1);
  border-color: rgba(26, 156, 95, 0.1);
}

.u-icon--primary-soft.disabled, .u-icon--primary-soft:disabled {
  background-color: rgba(33, 200, 122, 0.1);
  border-color: rgba(33, 200, 122, 0.1);
}

/* white-soft */
.u-icon--white-soft {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.u-icon--white-soft[href]:hover {
  color: #212529;
  background-color: rgba(236, 236, 236, 0.1);
  border-color: rgba(230, 230, 230, 0.1);
}

.u-icon--white-soft.disabled, .u-icon--white-soft:disabled {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

/* dark-soft */
.u-icon--dark-soft {
  color: #fff;
  background-color: rgba(21, 27, 38, 0.4);
  border-color: rgba(21, 27, 38, 0.4);
}

.u-icon--dark-soft[href]:hover {
  color: #fff;
  background-color: rgba(7, 9, 13, 0.4);
  border-color: rgba(3, 4, 5, 0.4);
}

.u-icon--dark-soft.disabled, .u-icon--dark-soft:disabled {
  background-color: rgba(21, 27, 38, 0.4);
  border-color: rgba(21, 27, 38, 0.4);
}

/* facebook */
.u-icon--facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}

.u-icon--facebook[href]:hover {
  color: #fff;
  background-color: #30497c;
  border-color: #2d4373;
}

.u-icon--facebook.disabled, .u-icon--facebook:disabled {
  background-color: #3b5998;
  border-color: #3b5998;
}

/* google */
.u-icon--google {
  color: #fff;
  background-color: #d14130;
  border-color: #d14130;
}

.u-icon--google[href]:hover {
  color: #fff;
  background-color: #b33628;
  border-color: #a93325;
}

.u-icon--google.disabled, .u-icon--google:disabled {
  background-color: #d14130;
  border-color: #d14130;
}

/* twitter */
.u-icon--twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.u-icon--twitter[href]:hover {
  color: #fff;
  background-color: #0d8ddc;
  border-color: #0c85d0;
}

.u-icon--twitter.disabled, .u-icon--twitter:disabled {
  background-color: #1da1f2;
  border-color: #1da1f2;
}

/* instagram */
.u-icon--instagram {
  color: #fff;
  background-color: #3f729b;
  border-color: #3f729b;
}

.u-icon--instagram[href]:hover {
  color: #fff;
  background-color: #345e80;
  border-color: #305777;
}

.u-icon--instagram.disabled, .u-icon--instagram:disabled {
  background-color: #3f729b;
  border-color: #3f729b;
}

/* github */
.u-icon--github {
  color: #fff;
  background-color: #24292e;
  border-color: #24292e;
}

.u-icon--github[href]:hover {
  color: #fff;
  background-color: #131619;
  border-color: #0e1011;
}

.u-icon--github.disabled, .u-icon--github:disabled {
  background-color: #24292e;
  border-color: #24292e;
}

/*------------------------------------
  Icon Sizes
------------------------------------*/
/* Extra Small */
.u-icon--xs {
  width: 1.375rem;
  height: 1.375rem;
  font-size: 0.5625rem;
  border-radius: 0.25rem;
}

/* Small */
.u-icon--sm {
  width: 2rem;
  height: 2rem;
  font-size: 0.8125rem;
  border-radius: 0.25rem;
}

/* Medium */
.u-icon--md {
  width: 4rem;
  height: 4rem;
  font-size: 1.75rem;
  border-radius: 0.25rem;
}

/* Large */
.u-icon--lg {
  width: 5rem;
  height: 5rem;
  font-size: 2.25rem;
  border-radius: 0.25rem;
}

/* Exatra Large */
.u-icon--xl {
  width: 6rem;
  height: 6rem;
  font-size: 2.25rem;
  border-radius: 0.25rem;
}

/*------------------------------------
  SVG Icon
------------------------------------*/
.svg-icon {
  display: inline-block;
}

.svg-icon[class*="text-"] > svg [fill]:not([fill="none"]) {
  fill: currentColor !important;
}

.svg-icon > svg {
  width: 3.5rem;
  height: 3.5rem;
}

.svg-icon-2xs > svg {
  width: 1.13rem;
  height: 1.13rem;
}

.svg-icon-xs > svg {
  width: 1.5rem;
  height: 1.5rem;
}

.svg-icon-sm > svg {
  width: 2.5rem;
  height: 2.5rem;
}

.svg-icon-lg > svg {
  width: 4.5rem;
  height: 4.5rem;
}

.svg-icon-xl > svg {
  width: 5.5rem;
  height: 5.5rem;
}

.svg-icon-stroke line,
.svg-icon-stroke path,
.svg-icon-stroke polyline,
.svg-icon-stroke polygon,
.svg-icon-stroke circle,
.svg-icon-stroke rect,
.svg-icon-stroke ellipse {
  fill: none;
  stroke-width: 1px;
}

.svg-icon-stroke-2 line,
.svg-icon-stroke-2 path,
.svg-icon-stroke-2 polyline,
.svg-icon-stroke-2 polygon,
.svg-icon-stroke-2 circle,
.svg-icon-stroke-2 rect,
.svg-icon-stroke-2 ellipse {
  stroke-width: 2px;
}

.svg-icon-stroke.text-primary line,
.svg-icon-stroke.text-primary path,
.svg-icon-stroke.text-primary polyline,
.svg-icon-stroke.text-primary polygon,
.svg-icon-stroke.text-primary circle,
.svg-icon-stroke.text-primary rect,
.svg-icon-stroke.text-primary ellipse {
  stroke: #21c87a;
}

.svg-icon-stroke.text-secondary line,
.svg-icon-stroke.text-secondary path,
.svg-icon-stroke.text-secondary polyline,
.svg-icon-stroke.text-secondary polygon,
.svg-icon-stroke.text-secondary circle,
.svg-icon-stroke.text-secondary rect,
.svg-icon-stroke.text-secondary ellipse {
  stroke: #646f79;
}

.svg-icon-stroke.text-success line,
.svg-icon-stroke.text-success path,
.svg-icon-stroke.text-success polyline,
.svg-icon-stroke.text-success polygon,
.svg-icon-stroke.text-success circle,
.svg-icon-stroke.text-success rect,
.svg-icon-stroke.text-success ellipse {
  stroke: #21c87a;
}

.svg-icon-stroke.text-danger line,
.svg-icon-stroke.text-danger path,
.svg-icon-stroke.text-danger polyline,
.svg-icon-stroke.text-danger polygon,
.svg-icon-stroke.text-danger circle,
.svg-icon-stroke.text-danger rect,
.svg-icon-stroke.text-danger ellipse {
  stroke: #da4b48;
}

.svg-icon-stroke.text-warning line,
.svg-icon-stroke.text-warning path,
.svg-icon-stroke.text-warning polyline,
.svg-icon-stroke.text-warning polygon,
.svg-icon-stroke.text-warning circle,
.svg-icon-stroke.text-warning rect,
.svg-icon-stroke.text-warning ellipse {
  stroke: #ffbc42;
}

.svg-icon-stroke.text-info line,
.svg-icon-stroke.text-info path,
.svg-icon-stroke.text-info polyline,
.svg-icon-stroke.text-info polygon,
.svg-icon-stroke.text-info circle,
.svg-icon-stroke.text-info rect,
.svg-icon-stroke.text-info ellipse {
  stroke: #00dffc;
}

/*------------------------------------
  Form
------------------------------------*/
.form {
  border-radius: 0.25rem;
}

.form__input {
  z-index: 2;
}

.form__append, .form__prepend,
.form__prepend > .btn,
.form__append > .btn, .form__text {
  background-clip: padding-box;
  transition: all 0.2s ease-in-out;
}

.form__append .btn {
  margin-left: 1px;
}

.form__text {
  position: relative;
}

.form__text-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.form--no-brd .form__input,
.form--no-brd .form__text {
  border-color: transparent;
}

.form--white-brd .form__input,
.form--white-brd .form__text {
  border-color: #fff !important;
}

.form--no-addon-brd .form__input:not(:first-child) {
  border-left: none;
}

.form--no-addon-brd .form__input:not(:last-child) {
  border-right: none;
}

/*------------------------------------
  Form Sizes
------------------------------------*/
.form .form__text {
  min-width: 3rem;
  font-size: 0.8125rem;
}

.form--sm .form__text {
  min-width: 2.6875rem;
}

.form--lg .form__text {
  font-size: 1.125rem;
  min-width: 3.875rem;
}

/*------------------------------------
  Input Dropdown
------------------------------------*/
.u-guests__input {
  cursor: pointer;
  z-index: 2;
}

.u-guests__input-i {
  fill: #bfc3ca;
  position: absolute;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 3;
}

.open .u-guests__input-i {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}

.u-guests-options {
  margin-top: .5rem;
  width: 87%;
  z-index: 1;
}

.u-guests-options__label {
  line-height: 1.5;
}

.u-guests-options__label-info {
  display: block;
  font-style: normal;
  color: #646f79;
}

.u-guests-options__ctrl, .u-guests-options__value {
  padding-top: .3rem;
  padding-bottom: .3rem;
}

.u-guests-options__ctrl {
  padding-left: .65rem;
  padding-right: .65rem;
  border: solid 1px #e3e6f0;
  cursor: pointer;
}

.u-guests-options__value {
  width: 3rem;
  text-align: center;
  border-top: solid 1px #e3e6f0;
  border-bottom: solid 1px #e3e6f0;
}

.u-guests-options__ctrl--minus {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.u-guests-options__ctrl--plus {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*------------------------------------
  Form Selector
------------------------------------*/
.form-selector {
  padding-left: 0;
  margin-right: .5rem;
  margin-bottom: .75rem;
}

.form-selector__input:checked ~ .form-selector__label {
  border-color: #21c87a;
}

.form-selector__label {
  text-align: center;
  cursor: pointer;
  min-width: 5rem;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.form-selector__label::before, .form-selector__label::after {
  display: none;
}

/*------------------------------------
  Form Selector Modern
------------------------------------*/
.form-selector-modern {
  width: 100%;
  padding-left: 0;
  margin-right: 0;
  margin-bottom: 1rem;
}

.form-selector-modern__input:checked ~ .form-selector-modern__label {
  border-color: #21c87a;
}

.form-selector-modern__label {
  width: 100%;
  cursor: pointer;
  border: 2px solid #e3e6f0;
  border-radius: 0.25rem;
  padding: 1.25rem;
  padding-left: 3rem;
}

.form-selector-modern__label::before, .form-selector-modern__label::after {
  top: 1.5rem;
  left: 1.25rem;
}

/*------------------------------------
  Success State
------------------------------------*/
.u-has-success .form.u-focus-state {
  box-shadow: 0 0 1.5625rem rgba(33, 200, 122, 0.1);
}

.u-has-success .form:focus,
.u-has-success .form.u-focus-state .form__text,
.u-has-success .form.u-focus-state .form__input,
.u-has-success .form.u-focus-state *:focus {
  border-color: #21c87a;
}

.u-has-success .form.u-focus-state .form__text {
  color: #21c87a;
}

/*------------------------------------
  Error State
------------------------------------*/
.u-has-error .form.u-focus-state {
  box-shadow: 0 0 1.5625rem rgba(218, 75, 72, 0.1);
}

.u-has-error .form:focus,
.u-has-error .form.u-focus-state .form__text,
.u-has-error .form.u-focus-state .form__input,
.u-has-error .form.u-focus-state *:focus {
  border-color: #da4b48;
}

.u-has-error .form.u-focus-state .form__text {
  color: #da4b48;
}

/*------------------------------------
  Focus States
------------------------------------*/
.form {
  transition: all 0.2s ease-in-out;
}

.form.u-focus-state {
  box-shadow: 0 0 1.5625rem rgba(100, 111, 121, 0.1);
}

.form:focus,
.form.u-focus-state .form__text,
.form.u-focus-state .form__input,
.form.u-focus-state *:focus {
  border-color: rgba(21, 27, 38, 0.5);
}

.form.u-focus-state .form__text {
  color: #151b26;
}

/* White Border */
.form--white-brd.u-focus-state .form__input {
  border-color: #fff;
}

/*------------------------------------
  Sidebar Navigation
------------------------------------*/
/* Disable content revealing on page load */
.u-sidebar[aria-labelledby] {
  opacity: 0;
}

.u-sidebar[aria-labelledby][role="tabpanel"] {
  opacity: 1;
}

.u-sidebar[aria-labelledby].u-unfold--css-animation, .u-sidebar[aria-labelledby].u-unfold--jquery-slide {
  opacity: 1;
}

.u-sidebar.u-unfold--css-animation.u-unfold--hidden {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.u-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 102;
  width: 18.75rem;
  height: 100%;
  background-color: #fff;
  box-shadow: -0.5rem 0 1.125rem rgba(21, 27, 38, 0.07);
}

.u-sidebar__body, .u-sidebar__content {
  height: 100%;
}

.u-sidebar--left {
  right: auto;
  left: 0;
  box-shadow: 0.5rem 0 1.125rem rgba(21, 27, 38, 0.07);
}

.u-sidebar__close {
  color: #646f79;
}

.u-sidebar__close:hover {
  color: #151b26;
  opacity: 1;
}

.u-sidebar__scroller {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.u-sidebar__container {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.u-sidebar__footer {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

/*------------------------------------
  Sidebar Panel
------------------------------------*/
/* Link */
.u-sidebar--panel__link {
  color: #646f79;
}

.u-sidebar--panel__link:hover {
  color: #21c87a;
}

/* Footer */
.u-sidebar--panel__footer-offset {
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 9.5rem;
}

.u-sidebar__footer--panel {
  background: #fff;
  border-top: 1px solid #e3e6f0;
}

/*------------------------------------
  Card
------------------------------------*/
.card-2-count {
  -webkit-column-count: 2;
          column-count: 2;
}

.card-3-count {
  -webkit-column-count: 3;
          column-count: 3;
}

.card-4-count {
  -webkit-column-count: 4;
          column-count: 4;
}

.card-columns {
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          grid-column-gap: 1rem;
          column-gap: 1rem;
  orphans: 1;
  widows: 1;
}

.card-columns .card {
  display: inline-block;
  width: 100%;
}

@media (min-width: 576px) {
  .card-sm-2-count {
    -webkit-column-count: 2;
            column-count: 2;
  }
  .card-sm-3-count {
    -webkit-column-count: 3;
            column-count: 3;
  }
  .card-sm-4-count {
    -webkit-column-count: 4;
            column-count: 4;
  }
  .card-sm-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            grid-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-sm-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .card-md-2-count {
    -webkit-column-count: 2;
            column-count: 2;
  }
  .card-md-3-count {
    -webkit-column-count: 3;
            column-count: 3;
  }
  .card-md-4-count {
    -webkit-column-count: 4;
            column-count: 4;
  }
  .card-md-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            grid-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-md-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .card-lg-2-count {
    -webkit-column-count: 2;
            column-count: 2;
  }
  .card-lg-3-count {
    -webkit-column-count: 3;
            column-count: 3;
  }
  .card-lg-4-count {
    -webkit-column-count: 4;
            column-count: 4;
  }
  .card-lg-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            grid-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-lg-columns .card {
    display: inline-block;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .card-xl-2-count {
    -webkit-column-count: 2;
            column-count: 2;
  }
  .card-xl-3-count {
    -webkit-column-count: 3;
            column-count: 3;
  }
  .card-xl-4-count {
    -webkit-column-count: 4;
            column-count: 4;
  }
  .card-xl-columns {
    -webkit-column-gap: 1rem;
       -moz-column-gap: 1rem;
            grid-column-gap: 1rem;
            column-gap: 1rem;
    orphans: 1;
    widows: 1;
  }
  .card-xl-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.card-collapse {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  background-color: transparent;
}

.card-collapse__header {
  padding: 0;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.card-collapse__btn {
  color: #151b26;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  white-space: inherit;
}

.card-collapse__btn-arrow {
  color: #21c87a;
  margin-left: 1rem;
  transition: 0.3s ease-in-out;
}

.card-collapse__btn-arrow-inner {
  font-size: .8125rem;
}

.collapsed .card-collapse__btn-arrow {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.card-collapse__body {
  color: #646f79;
}

.card-img-right {
  width: 100%;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.card-img-left {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

/* Card Frame */
.card-frame {
  border: 1px solid #e3e6f0;
  border-top-width: 0.25rem;
  transition: 0.3s;
}

.card-frame.active, .card-frame:hover {
  border-color: rgba(33, 200, 122, 0.7);
  box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.25);
}

/* Card Shadow */
.card-shadow {
  transition: 0.3s;
}

.card-shadow:hover {
  box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.25);
}

/* Text */
.card-text-dark {
  color: #151b26;
}

.card-text-dark:hover {
  color: #21c87a;
}

.card-primary-frame.active, .card-primary-frame:hover {
  border-color: #21c87a;
}

.card-secondary-frame.active, .card-secondary-frame:hover {
  border-color: #646f79;
}

.card-success-frame.active, .card-success-frame:hover {
  border-color: #21c87a;
}

.card-info-frame.active, .card-info-frame:hover {
  border-color: #00dffc;
}

.card-warning-frame.active, .card-warning-frame:hover {
  border-color: #ffbc42;
}

.card-danger-frame.active, .card-danger-frame:hover {
  border-color: #da4b48;
}

.card-light-frame.active, .card-light-frame:hover {
  border-color: #f5f8fb;
}

.card-dark-frame.active, .card-dark-frame:hover {
  border-color: #151b26;
}

.card-purple-frame.active, .card-purple-frame:hover {
  border-color: #796eff;
}

.card-blue-frame.active, .card-blue-frame:hover {
  border-color: #1c6be1;
}

.card-primary-soft-frame.active, .card-primary-soft-frame:hover {
  border-color: rgba(33, 200, 122, 0.1);
}

.card-white-soft-frame.active, .card-white-soft-frame:hover {
  border-color: rgba(255, 255, 255, 0.1);
}

.card-dark-soft-frame.active, .card-dark-soft-frame:hover {
  border-color: rgba(21, 27, 38, 0.4);
}

@media (min-width: 576px) {
  /* Gutters X */
  .card-sm-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-sm-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-sm-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-sm-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-sm-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-sm-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media (min-width: 768px) {
  /* Gutters X */
  .card-md-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-md-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-md-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-md-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-md-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-md-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media (min-width: 992px) {
  /* Gutters X */
  .card-lg-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-lg-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-lg-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-lg-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-lg-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-lg-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

@media (min-width: 1200px) {
  /* Gutters X */
  .card-xl-gutters-1 {
    margin-right: -.25rem;
    margin-left: -.25rem;
  }
  .card-xl-gutters-1 .card {
    margin-right: .25rem;
    margin-left: .25rem;
  }
  .card-xl-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem;
  }
  .card-xl-gutters-2 .card {
    margin-right: .5rem;
    margin-left: .5rem;
  }
  .card-xl-gutters-3 {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-xl-gutters-3 .card {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

/*------------------------------------
  Clients
------------------------------------*/
.u-clients {
  width: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.u-clients-list .slick-slide div {
  line-height: .7;
}

.u-clients-list .slick-slide:last-child .u-clients-item {
  border-right: 0;
}

.u-clients-list .slick-slide div:last-child .u-clients-item {
  border-bottom: 0;
}

.u-clients-item {
  border-width: 0 1px 1px 0;
  border-color: #e3e6f0;
  border-style: solid;
}

/*------------------------------------
  Go To
------------------------------------*/
.u-go-to {
  display: none;
  position: relative;
  z-index: 100;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #21c87a;
  color: #fff;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  opacity: 0.5;
  transition: 0.3s ease-out;
}

.u-go-to__inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-go-to:hover, .u-go-to:focus:hover {
  color: #fff;
  opacity: 1;
}

/*------------------------------------
  Label
------------------------------------*/
.u-label {
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.625rem 1.125rem;
  border-radius: 6.1875rem;
  transition: 0.2s ease-in-out;
}

.u-label--rounded {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Label Sizes
------------------------------------*/
/* Small */
.u-label--sm {
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
}

/*------------------------------------
  Label Colors
------------------------------------*/
/* label primary */
.u-label--primary {
  color: #21c87a;
  background-color: rgba(33, 200, 122, 0.1);
}

.u-label--primary[href]:hover, .u-label--primary[href]:focus {
  color: #fff;
  background-color: #21c87a;
}

/* label secondary */
.u-label--secondary {
  color: #646f79;
  background-color: rgba(100, 111, 121, 0.1);
}

.u-label--secondary[href]:hover, .u-label--secondary[href]:focus {
  color: #fff;
  background-color: #646f79;
}

/* label success */
.u-label--success {
  color: #21c87a;
  background-color: rgba(33, 200, 122, 0.1);
}

.u-label--success[href]:hover, .u-label--success[href]:focus {
  color: #fff;
  background-color: #21c87a;
}

/* label info */
.u-label--info {
  color: #00dffc;
  background-color: rgba(0, 223, 252, 0.1);
}

.u-label--info[href]:hover, .u-label--info[href]:focus {
  color: #212529;
  background-color: #00dffc;
}

/* label warning */
.u-label--warning {
  color: #ffbc42;
  background-color: rgba(255, 188, 66, 0.1);
}

.u-label--warning[href]:hover, .u-label--warning[href]:focus {
  color: #212529;
  background-color: #ffbc42;
}

/* label danger */
.u-label--danger {
  color: #da4b48;
  background-color: rgba(218, 75, 72, 0.1);
}

.u-label--danger[href]:hover, .u-label--danger[href]:focus {
  color: #fff;
  background-color: #da4b48;
}

/* label light */
.u-label--light {
  color: #f5f8fb;
  background-color: rgba(245, 248, 251, 0.1);
}

.u-label--light[href]:hover, .u-label--light[href]:focus {
  color: #212529;
  background-color: #f5f8fb;
}

/* label dark */
.u-label--dark {
  color: #151b26;
  background-color: rgba(21, 27, 38, 0.1);
}

.u-label--dark[href]:hover, .u-label--dark[href]:focus {
  color: #fff;
  background-color: #151b26;
}

/* label purple */
.u-label--purple {
  color: #796eff;
  background-color: rgba(121, 110, 255, 0.1);
}

.u-label--purple[href]:hover, .u-label--purple[href]:focus {
  color: #fff;
  background-color: #796eff;
}

/* label blue */
.u-label--blue {
  color: #1c6be1;
  background-color: rgba(28, 107, 225, 0.1);
}

.u-label--blue[href]:hover, .u-label--blue[href]:focus {
  color: #fff;
  background-color: #1c6be1;
}

/* label primary-soft */
.u-label--primary-soft {
  color: rgba(33, 200, 122, 0.1);
  background-color: rgba(33, 200, 122, 0.1);
}

.u-label--primary-soft[href]:hover, .u-label--primary-soft[href]:focus {
  color: #fff;
  background-color: rgba(33, 200, 122, 0.1);
}

/* label white-soft */
.u-label--white-soft {
  color: rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
}

.u-label--white-soft[href]:hover, .u-label--white-soft[href]:focus {
  color: #212529;
  background-color: rgba(255, 255, 255, 0.1);
}

/* label dark-soft */
.u-label--dark-soft {
  color: rgba(21, 27, 38, 0.4);
  background-color: rgba(21, 27, 38, 0.1);
}

.u-label--dark-soft[href]:hover, .u-label--dark-soft[href]:focus {
  color: #fff;
  background-color: rgba(21, 27, 38, 0.4);
}

/*------------------------------------
  Boxed Layout
------------------------------------*/
.boxed-layout {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: #f5f8fb;
}

@media (min-width: 992px) {
  .boxed-layout {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }
}

.boxed-layout__inner {
  box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.125);
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .boxed-layout__section {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

/*------------------------------------
  Modal Window
------------------------------------*/
.u-modal-window {
  position: relative;
  display: none;
  max-height: 88vh;
  background-color: #fff;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.u-modal-window__close {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
}

/*------------------------------------
  Media Player
------------------------------------*/
.u-media-player {
  color: #151b26;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
}

.u-media-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 3.75rem;
  height: 3.75rem;
  font-size: 0.8125rem;
}

.u-media-player__icon::before {
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.u-media-player__icon-inner {
  color: #21c87a;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: .125rem;
}

.u-media-player__icon--box-shadow::before {
  box-shadow: 0 0 2.5rem #e3e6f0;
}

.u-media-player:hover .u-media-player__icon::before {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Extra Large Size */
.u-media-player__icon--xl {
  width: 6.25rem;
  height: 6.25rem;
  font-size: 1rem;
}

/*------------------------------------
  Media Viewer
------------------------------------*/
.u-media-viewer {
  position: relative;
  display: block;
  overflow: hidden;
}

.u-media-viewer:hover .u-media-viewer__img {
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
}

.u-media-viewer:hover .u-media-viewer__icon {
  opacity: 1;
}

.u-media-viewer__img {
  transition: 0.4s;
}

.u-media-viewer__container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;
}

.u-media-viewer__icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  background-color: #21c87a;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  border-radius: 50%;
  opacity: 0;
  transition: 0.4s;
}

.u-media-viewer__icon:hover, .u-media-viewer__icon:focus {
  color: #fff;
}

.u-media-viewer__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*------------------------------------
  Video Player
------------------------------------*/
.u-video-player {
  position: relative;
  background-color: #000;
}

.u-video-player__preview {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 1;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__preview {
  opacity: 0;
  pointer-events: none;
}

.u-video-player__btn {
  z-index: 3;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.u-video-player__played .u-video-player__btn {
  -webkit-animation: videoPlayerButton 0.4s ease-in-out forwards;
          animation: videoPlayerButton 0.4s ease-in-out forwards;
  pointer-events: none;
}

.u-video-player__btn:hover .u-video-player__icon::before, .u-video-player__btn:focus .u-video-player__icon::before {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.u-video-player__icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 6rem;
  height: 6rem;
  font-size: 0.8125rem;
}

.u-video-player__icon--sm {
  width: 5.5rem;
  height: 5.5rem;
  font-size: 1.5rem;
}

.u-video-player__icon::before {
  display: inline-block;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  border-radius: 50%;
  transition: 0.4s;
}

.u-video-player__icon-inner {
  color: #21c87a;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 2px;
}

@media (max-width: 767.98px) {
  .u-video-player__icon {
    width: 4rem;
    height: 4rem;
  }
}

.embed-responsive-invisible {
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.u-video-player__played .embed-responsive-invisible {
  opacity: 1;
}

/* Positions */
.u-video-player__centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@-webkit-keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes videoPlayerButton {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(1.3);
            transform: translate(-50%, -50%) scale(1.3);
  }
}

/*------------------------------------
  Pagination
------------------------------------*/
.page-link {
  text-align: center;
  min-width: 2.25rem;
}

.page-item {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.page-item .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/*------------------------------------
  Vertical Progress Bar
------------------------------------*/
.progress-vertical {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 12.5rem;
}

.progress-vertical__inner {
  border-radius: 6.1875rem;
}

/*------------------------------------
  Quantity
------------------------------------*/
.u-quantity {
  width: 4rem;
}

.u-quantity > .u-quantity__input {
  padding-right: 1.875rem;
}

.u-quantity > .u-quantity__input:not(:last-child) {
  border-radius: 0.25rem;
}

.u-quantity__arrows {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  right: .75rem;
  z-index: 3;
}

.u-quantity__arrows-inner {
  display: block;
  color: #646f79;
  cursor: pointer;
}

.u-quantity__arrows-inner:hover {
  color: #646f79;
}

/*------------------------------------
  Shopping Cart
------------------------------------*/
/* Item */
.u-shopping-cart {
  border: 1px solid #e3e6f0;
  margin-top: 1.3125rem;
  border-radius: 0;
}

.u-shopping-cart__item-divider {
  border-bottom: 1px solid #e3e6f0;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.u-shopping-cart__item-img-wrapper {
  width: 25%;
}

.u-shopping-cart__item-info-wrapper {
  width: 75%;
}

.u-shopping-cart__item-remover {
  cursor: pointer;
  color: #bfc3ca;
}

.u-shopping-cart__item-remover:hover {
  color: #151b26;
}

/*------------------------------------
  Shopping Cart - Color Selector
------------------------------------*/
.u-shopping-cart-color-selector {
  display: inline-block;
  cursor: pointer;
  width: 1.75rem;
  height: 1.75rem;
  padding: .25rem;
  border: 3px solid #fff;
  border-radius: 50%;
}

.u-shopping-cart-color-selector.active {
  box-shadow: 0 0 0 2px #bfc3ca;
}

.u-shopping-cart-color-selector.active:hover {
  box-shadow: 0 0 0 2px #bfc3ca;
}

.u-shopping-cart-color-selector:hover {
  box-shadow: 0 0 0 2px #e3e6f0;
}

/*------------------------------------
  Shopping Cart - Step Form
------------------------------------*/
.u-shopping-cart-step-form {
  margin-left: 0;
  text-align: center;
}

.u-shopping-cart-step-form__item {
  width: 100%;
  border-bottom: 2px solid #e3e6f0;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-right: -.25rem;
  margin-left: -.25rem;
}

.u-shopping-cart-step-form__item:not(:last-child) {
  margin-right: 0;
}

@media (min-width: 576px) {
  .u-shopping-cart-step-form__item {
    width: 33.33333%;
  }
}

.u-shopping-cart-step-form__item.active {
  border-bottom-color: #21c87a;
}

.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__title,
.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__subtitle {
  color: #21c87a;
}

.u-shopping-cart-step-form__title {
  display: block;
  font-size: 1.25rem;
  color: #151b26;
}

.u-shopping-cart-step-form__subtitle {
  display: block;
  font-size: .875rem;
  color: #8c98a4;
}

/* Toggler */
.u-shopping-cart-step-form__toggler {
  display: block;
  width: 100%;
  text-align: center;
  color: #646f79;
  border: 1px solid #e3e6f0;
  border-radius: 0.25rem;
  padding: 1.5rem;
  min-width: 13.75rem;
  margin-right: 1rem;
  transition: 0.3s;
}

.u-shopping-cart-step-form__toggler.active, .u-shopping-cart-step-form__toggler:hover {
  color: #21c87a;
  border-color: #21c87a;
}

.u-shopping-cart-step-form__toggler-icon {
  font-size: 1.5rem;
}

.u-shopping-cart-step-form__toggler-img {
  width: 5rem;
  height: auto;
}

/*------------------------------------
  Slick
------------------------------------*/
.u-slick {
  position: relative;
}

.u-slick--rounded .slick-list {
  border-radius: 0.25rem;
}

.u-slick--cursor-pointer .slick-slide {
  cursor: pointer;
}

.u-slick--transform-off.slick-transform-off .slick-track,
.u-slick--transform-off .slick-track {
  -webkit-transform: none !important;
          transform: none !important;
}

@media (min-width: 992px) {
  .u-slick--transform-off-lg.slick-transform-off .slick-track {
    -webkit-transform: none !important;
            transform: none !important;
  }
}

.u-slick--center-mode-right {
  margin-right: calc(-1px - ((100vw - 100%) / 2) + 10px) !important;
}

.u-slick--center-mode-right .slick-list {
  padding-left: 0 !important;
}

@media (max-width: 767.98px) {
  .slick-list {
    padding-right: 0 !important;
  }
}

.u-slick--blurex::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 135px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.u-slick--video .slick-slide {
  position: relative;
  cursor: pointer;
}

.u-slick--video .slick-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color .3s ease-out;
}

.u-slick--video .slick-slide.slick-current::before {
  background-color: transparent;
}

.u-slick--video__player {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  transition: opacity 0.3s ease-out;
  opacity: 1;
}

.slick-current .u-slick--video__player {
  opacity: 0;
}

/*------------------------------------
  Slick Equal Height
------------------------------------*/
.u-slick--equal-height .slick-list {
  height: 100%;
}

.u-slick--equal-height .slick-track {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.u-slick--equal-height .slick-track .slick-slide {
  display: -webkit-flex;
  display: flex;
  height: auto;
}

/*------------------------------------
  Slick Gutters
------------------------------------*/
.u-slick--gutters {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.u-slick--gutters .slick-slide {
  margin-left: .125rem;
  margin-right: .125rem;
}

.u-slick--gutters-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.u-slick--gutters-2 .slick-slide {
  margin-left: .5rem;
  margin-right: .5rem;
}

.u-slick--gutters-3 {
  margin-left: -.9375rem;
  margin-right: -.9375rem;
}

.u-slick--gutters-3 .slick-slide {
  margin-left: .9375rem;
  margin-right: .9375rem;
}

/*------------------------------------
  Slick Arrows
------------------------------------*/
.u-slick__arrow {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #646f79;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.8125rem;
  cursor: pointer;
  transition: 0.3s;
}

.u-slick__arrow:hover {
  color: #21c87a;
}

.u-slick__arrow-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick__arrow-inner--left {
  left: 0;
}

.u-slick__arrow-inner--right {
  right: 0;
}

.u-slick__arrow.slick-disabled {
  color: #e3e6f0;
  cursor: auto;
}

/* Modern Version */
.u-slick__arrow--shadowed {
  color: #646f79;
  background-color: #fff;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(140, 152, 164, 0.25);
}

.u-slick__arrow-pos--bottom-minus-7 {
  position: absolute;
  bottom: -4rem;
}

/* Center Alignment */
.u-slick__arrow-centered--x {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.u-slick__arrow-centered--y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

/*------------------------------------
  Slick Arrows Classic
------------------------------------*/
.u-slick__arrow-classic {
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.8125rem;
  cursor: pointer;
  transition: 0.3s;
}

.u-slick__arrow-classic--lg {
  width: 3.65rem;
  height: 3.65rem;
  font-size: 1.18625rem;
}

.u-slick__arrow-classic:hover {
  color: #fff;
  background-color: #21c87a;
}

.u-slick__arrow-classic-inner:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick__arrow-classic-inner--left {
  left: 0;
}

.u-slick__arrow-classic-inner--right {
  right: 0;
}

/* Dark Version */
.u-slick__arrow-classic--dark {
  color: #fff;
  background-color: #646f79;
}

/*------------------------------------
  Slick Paging v1
------------------------------------*/
.u-slick__paging-v1 {
  position: absolute;
  bottom: 32px;
  right: 0;
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.u-slick__paging-v1 .u-paging__current {
  color: #fff;
  font-size: 3.875rem;
  line-height: 1;
}

.u-slick__paging-v1 .u-paging__divider {
  position: relative;
  margin-right: .5rem;
  margin-left: .25rem;
}

.u-slick__paging-v1 .u-paging__divider::before {
  position: absolute;
  top: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: .875rem;
  content: "\002f";
}

.u-slick__paging-v1 .u-paging__total {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
}

/*------------------------------------
  Slick Pagination
------------------------------------*/
.u-slick__pagination {
  padding-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  pointer-events: none;
}

.u-slick__pagination li {
  display: -webkit-flex;
  display: flex;
  pointer-events: all;
  margin: 0 .25rem;
  cursor: pointer;
}

.u-slick__pagination li span {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  background-color: #8c98a4;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  transition: 0.3s;
}

.u-slick__pagination li.slick-active span {
  background-color: transparent;
  border-color: #8c98a4;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/* White Option */
.u-slick__pagination.u-slick__pagination--white li span {
  background-color: #fff;
}

.u-slick__pagination.u-slick__pagination--white li.slick-active span {
  background-color: transparent;
  border-color: #fff;
}

/*------------------------------------
  Slick Pagination - Active Border
------------------------------------*/
.u-slick--pagination-active-border .u-slick--pagination-active-border__item {
  cursor: pointer;
  border: 1px solid #e3e6f0;
}

.u-slick--pagination-active-border .slick-current .u-slick--pagination-active-border__item {
  border-color: #21c87a;
}

/*------------------------------------
  Slick Pagination - Active Transition
------------------------------------*/
.u-slick--active-transition .slick-slide {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
  transition: 0.3s;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.u-slick--active-transition .slick-current {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*------------------------------------
  Slick Pagination Bordered
------------------------------------*/
.u-slick__pagination-bordered {
  padding-left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  pointer-events: none;
}

.u-slick__pagination-bordered li {
  display: -webkit-flex;
  display: flex;
  pointer-events: all;
  margin: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  cursor: pointer;
}

.u-slick__pagination-bordered li span {
  display: inline-block;
  width: 4rem;
  height: .25rem;
  box-sizing: border-box;
  background-color: #8c98a4;
  transition: 0.3s;
}

.u-slick__pagination-bordered li:hover span {
  background-color: rgba(33, 200, 122, 0.4);
}

.u-slick__pagination-bordered li.slick-active span {
  background-color: #21c87a;
}

/* White Option */
.u-slick__pagination-bordered.u-slick__pagination-bordered--white li span {
  background-color: rgba(255, 255, 255, 0.5);
}

.u-slick__pagination-bordered.u-slick__pagination-bordered--white li:hover span {
  background-color: rgba(255, 255, 255, 0.7);
}

.u-slick__pagination-bordered.u-slick__pagination-bordered--white li.slick-active span {
  background-color: #fff;
}

/*------------------------------------
  Slick Pagination Classic
------------------------------------*/
.u-slick--pagination-classic .slick-slide {
  cursor: pointer;
  opacity: .5;
  border-top: 4px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

.u-slick--pagination-classic .slick-current {
  opacity: 1;
  border-top-color: #fff;
}

/*------------------------------------
  Slick Pagination Modern
------------------------------------*/
.u-slick--pagination-modern {
  margin-right: -.9375rem;
}

.u-slick--pagination-modern__item {
  padding-right: .9375rem;
}

@media (min-width: 992px) {
  .u-slick--pagination-modern__item {
    max-width: 25%;
  }
}

.u-slick--pagination-modern__item-img {
  width: 6.25rem;
  height: 4.375rem;
}

.u-slick--pagination-modern__item-text {
  transition: 0.3s;
}

.u-slick--pagination-modern__item.slick-current {
  color: #21c87a;
}

.u-slick--pagination-modern__item:hover .u-slick--pagination-modern__item-text {
  color: #21c87a;
}

/*------------------------------------
  Slick Pagination - Simple
------------------------------------*/
.u-slick--pagination-simple .u-slick--pagination-simple__item {
  cursor: pointer;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.u-slick--pagination-simple .u-slick--pagination-simple__icon {
  position: relative;
  z-index: 1;
  line-height: 0.7;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 2.75rem;
  height: 2.75rem;
  color: #646f79;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 50%;
  border: 1px solid #e3e6f0;
  transition: 0.3s;
}

.u-slick--pagination-simple .u-slick--pagination-simple__icon-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.u-slick--pagination-simple .slick-current .u-slick--pagination-simple__title,
.u-slick--pagination-simple .slick-current .u-slick--pagination-simple__text {
  color: #21c87a;
}

.u-slick--pagination-simple .slick-current .u-slick--pagination-simple__icon {
  color: #21c87a;
  border-color: #21c87a;
}

/*------------------------------------
  Tab Vertical
------------------------------------*/
.tab-vertical .tab-vertical__nav-link {
  color: #646f79;
  font-weight: 400;
  border-radius: 0;
  padding-left: 0;
}

.tab-vertical .tab-vertical__nav-link.active {
  color: #21c87a;
  background-color: transparent;
}

.tab-vertical .tab-vertical__nav-link:hover {
  color: #21c87a;
}

@media (min-width: 768px) {
  .tab-vertical-md {
    position: relative;
    box-shadow: 26px 0 26px -12px rgba(100, 111, 121, 0.06);
  }
  .tab-vertical-md::after {
    position: absolute;
    top: 0;
    right: 0;
    width: .25rem;
    height: 100%;
    background-color: rgba(100, 111, 121, 0.09);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    content: " ";
  }
  .tab-vertical-md .tab-vertical__nav-link {
    position: relative;
  }
  .tab-vertical-md .tab-vertical__nav-link::after {
    position: absolute;
    top: 0;
    right: 0;
    width: .25rem;
    height: 100%;
    background-color: transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    content: " ";
  }
  .tab-vertical-md .tab-vertical__nav-link.active::after {
    background-color: #21c87a;
  }
  .tab-vertical-md .tab-vertical__nav-link:hover {
    color: #21c87a;
  }
}

/*------------------------------------
  Tab Modern
------------------------------------*/
.tab-modern {
  border-bottom: none;
}

.tab-modern .tab-modern__nav-link {
  min-width: 15rem;
  color: #646f79;
  text-align: center;
  font-weight: 400;
  border: none;
  border-bottom: 2px solid #e3e6f0;
  border-radius: 0;
  padding: .5rem;
}

.tab-modern .tab-modern__nav-link-icon {
  display: block;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
}

.tab-modern .tab-modern__nav-link.active {
  color: #21c87a;
  border-bottom-color: #21c87a;
  background-color: transparent;
}

.tab-modern .tab-modern__nav-link.active .tab-modern__nav-link-icon {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

.tab-modern .tab-modern__nav-link:hover {
  color: #21c87a;
}

.tab-modern .tab-modern__nav-link:hover .tab-modern__nav-link-icon {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}

/*------------------------------------
  Timeline
------------------------------------*/
.u-timeline {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.u-timeline__item {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -.9375rem;
  margin-left: -.9375rem;
}

@media (min-width: 992px) {
  .u-timeline__item {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .u-timeline__item:nth-child(odd) .u-timeline__content {
    -webkit-order: 2;
            order: 2;
    padding-left: 3rem;
  }
  .u-timeline__item:nth-child(odd) .u-timeline__info {
    -webkit-order: 1;
            order: 1;
  }
  .u-timeline__item:nth-child(odd) .u-timeline__spacer {
    -webkit-order: 3;
            order: 3;
  }
}

@media (min-width: 992px) {
  .u-timeline__item:nth-child(even) .u-timeline__content {
    -webkit-order: 2;
            order: 2;
    padding-left: 3rem;
  }
  .u-timeline__item:nth-child(even) .u-timeline__info {
    -webkit-order: 3;
            order: 3;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .u-timeline__item:nth-child(even) .u-timeline__spacer {
    -webkit-order: 1;
            order: 1;
  }
}

.u-timeline__icon {
  position: absolute;
  top: 0;
  left: .9375rem;
}

@media (min-width: 992px) {
  .u-timeline__icon {
    left: 0;
  }
}

.u-timeline__content, .u-timeline__info {
  width: calc(100% - 4rem);
}

.u-timeline__info, .u-timeline__spacer {
  display: block;
}

@media (min-width: 992px) {
  .u-timeline__info, .u-timeline__spacer {
    width: 33%;
  }
}

.u-timeline__info, .u-timeline__content, .u-timeline__spacer {
  padding-right: .9375rem;
  padding-left: .9375rem;
}

@media (max-width: 991.98px) {
  .u-timeline__info, .u-timeline__content, .u-timeline__spacer {
    padding-left: 4rem;
  }
}

.u-timeline__info, .u-timeline__content {
  position: relative;
}

.u-timeline__info::before, .u-timeline__content::before {
  position: absolute;
  top: .5rem;
  bottom: 0;
  left: 1.875rem;
  height: 100%;
  border-left: 3px dashed #e3e6f0;
  content: "";
}

@media (min-width: 992px) {
  .u-timeline__info::before, .u-timeline__content::before {
    left: .9375rem;
  }
}

.u-timeline__content {
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .u-timeline__content {
    width: 50%;
    padding-bottom: 4rem;
  }
}

.u-timeline__info {
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .u-timeline__info {
    padding-bottom: 0;
  }
  .u-timeline__info::before {
    display: none;
  }
}

/*------------------------------------
  Background Colors
------------------------------------*/
.bg-gray-100 {
  background-color: #f5f8fb;
}

.bg-black-opacity-0_3 {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-purple-opacity-0_07 {
  background-color: rgba(121, 110, 255, 0.07);
}

/*------------------------------------
  Background Image Style
------------------------------------*/
.bg-img-hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.bg-img-hero-bottom {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.bg-img-hero-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

/*------------------------------------
  Borders
------------------------------------*/
.rounded-pill {
  border-radius: 6.1875rem !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-white-opacity-0_3 {
  border-color: rgba(255, 255, 255, 0.3);
}

.rounded-pseudo {
  border-radius: 0.25rem;
}

.rounded-pseudo::before, .rounded-pseudo::after {
  border-radius: 0.25rem;
}

@media (min-width: 992px) {
  .rounded-lg-right-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

/*------------------------------------
  Content Centered
------------------------------------*/
.content-centered-y {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.content-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

/*------------------------------------
  Fill Colors
------------------------------------*/
.fill-none {
  fill: none !important;
}

/*------------------------------------
  Background Gradients
------------------------------------*/
.gradient-half-primary-v1 {
  background-image: linear-gradient(25deg, #796eff 0%, #21c87a 100%);
  background-repeat: repeat-x;
}

.gradient-half-purple-v1 {
  background-image: linear-gradient(35deg, #796eff 45%, #1c6be1 100%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Letter Spacing
------------------------------------*/
.letter-spacing-0_06 {
  letter-spacing: .06rem;
}

/*------------------------------------
  Lift
------------------------------------*/
[class*="lift"] {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.lift:focus, .lift:hover {
  -webkit-transform: translate3d(0, -3px, 0);
          transform: translate3d(0, -3px, 0);
}

.lift-lg:focus, .lift-lg:hover {
  -webkit-transform: translate3d(0, -6px, 0);
          transform: translate3d(0, -6px, 0);
}

/*------------------------------------
  Background Overlay Gradients
------------------------------------*/
[class*="gradient-overlay"],
[class*="gradient-overlay-half"],
[class*="gradient-overlay-quarter"] {
  position: relative;
  z-index: 1;
}

[class*="gradient-overlay"]::before, [class*="gradient-overlay"]::after,
[class*="gradient-overlay-half"]::before,
[class*="gradient-overlay-half"]::after,
[class*="gradient-overlay-quarter"]::before,
[class*="gradient-overlay-quarter"]::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
}

.gradient-overlay-half-dark-video-v1::before {
  z-index: 2;
  background-color: rgba(21, 27, 38, 0.95);
}

.gradient-overlay-half-white-v1::before {
  background-image: linear-gradient(60deg, rgba(255, 255, 255, 0.99) 30%, rgba(255, 255, 255, 0) 60%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-white-v2::before {
  background-image: linear-gradient(60deg, rgba(255, 255, 255, 0.99) 28%, rgba(255, 255, 255, 0) 50%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-white-v2::after {
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.99) 3%, rgba(255, 255, 255, 0) 50%);
  background-repeat: repeat-x;
}

.gradient-overlay-quarter-light-v1::before {
  background-image: linear-gradient(0deg, #f5f8fb 0%, rgba(255, 255, 255, 0) 25%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v1::before {
  background-image: linear-gradient(30deg, rgba(21, 27, 38, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v2::before {
  background-image: linear-gradient(0deg, rgba(21, 27, 38, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v2-2::before {
  background-image: linear-gradient(0deg, rgba(21, 27, 38, 0.89) 0%, rgba(21, 27, 38, 0.37) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v2-3::before {
  background-image: linear-gradient(0deg, #151b26 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v3::before {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(21, 27, 38, 0.5) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-dark-v4::before {
  background-image: linear-gradient(150deg, rgba(21, 27, 38, 0.9) 10%, #151b26 50%);
  background-repeat: repeat-x;
}

.gradient-overlay-dark-v1::before {
  background-image: linear-gradient(0deg, #151b26 0%, rgba(0, 0, 0, 0) 150%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-black-v1::before {
  background-image: linear-gradient(45deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-black-v1::after {
  background-image: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 25%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-black-v1-2::before {
  background-image: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 25%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-black-v1-2::after {
  background-image: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-black-v2::before {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 50%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-primary-v1::before {
  background-image: linear-gradient(30deg, rgba(121, 110, 255, 0.95) 0%, rgba(33, 200, 122, 0.95) 100%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-purple-v1::before {
  background-image: linear-gradient(180deg, rgba(121, 110, 255, 0.35) 10%, #796eff 70%);
  background-repeat: repeat-x;
}

.gradient-overlay-half-purple-v2::before {
  background-image: linear-gradient(150deg, rgba(121, 110, 255, 0.9) 10%, #796eff 50%);
  background-repeat: repeat-x;
}

/*------------------------------------
  Margin Spacing
------------------------------------*/
.mb-offset-14 {
  margin-bottom: -7rem;
}

/* Gutters X */
.mx-gutters-1 {
  margin-right: -.25rem;
  margin-left: -.25rem;
}

.mx-gutters-1 > .col,
.mx-gutters-1 > [class*="col-"] {
  padding-right: .25rem;
  padding-left: .25rem;
}

.mx-gutters-2 {
  margin-right: -.5rem;
  margin-left: -.5rem;
}

.mx-gutters-2 > .col,
.mx-gutters-2 > [class*="col-"] {
  padding-right: .5rem;
  padding-left: .5rem;
}

/*------------------------------------
  Opacity
------------------------------------*/
.opacity-lg {
  opacity: .5 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity--hover-default:hover {
  opacity: 1 !important;
}

/*------------------------------------
  Position Spaces
------------------------------------*/
.position-absolute-top-right-0 {
  position: absolute;
  top: 0;
  right: 0;
}

.position-absolute-bottom-0 {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.position-absolute-bottom-left-0 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.position-absolute-bottom-right-0 {
  position: absolute;
  bottom: 0;
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.left-1 {
  left: 1rem;
}

.right-1 {
  right: 1rem;
}

@media (min-width: 576px) {
  .left-sm-3 {
    left: 3rem;
  }
  .right-sm-3 {
    right: 3rem;
  }
}

@media (min-width: 576px) {
  .position-absolute-bottom-0--sm {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 768px) {
  .position-absolute-top-left-0--md {
    position: absolute;
    top: 0;
    left: 0;
  }
  .position-absolute-top-right-0--md {
    position: absolute;
    top: 0;
    right: 0;
  }
  .position-absolute-bottom-left-0--md {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: 992px) {
  .position-absolute-top-left-0--lg {
    position: absolute;
    top: 0;
    left: 0;
  }
  .position-absolute-top-right-0--lg {
    position: absolute;
    top: 0;
    right: 0;
  }
  .position-absolute-bottom-0--lg {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

/*------------------------------------
  Shadows
------------------------------------*/
.shadow-xs {
  box-shadow: 0 0 1.5rem rgba(140, 152, 164, 0.125) !important;
}

.shadow-2 {
  box-shadow: 0 1rem 2.5rem rgba(21, 27, 38, 0.1) !important;
}

.shadow-2-xs {
  box-shadow: 0 0.25rem 0.56rem rgba(21, 27, 38, 0.1) !important;
}

.shadow-2-sm {
  box-shadow: 0 0.5rem 1.125rem rgba(21, 27, 38, 0.1) !important;
}

.shadow-2-lg {
  box-shadow: 0 2rem 5rem rgba(21, 27, 38, 0.1) !important;
}

.shadow-2-sm-hover, .shadow-2-hover, .shadow-2-lg-hover {
  transition: all 0.2s ease-in-out;
}

.shadow-2-sm-hover:hover {
  box-shadow: 0 0.5rem 1.125rem rgba(21, 27, 38, 0.1) !important;
}

.shadow-2-hover:hover {
  box-shadow: 0 1rem 2.5rem rgba(21, 27, 38, 0.1) !important;
}

.shadow-2-lg-hover:hover {
  box-shadow: 0 2rem 5rem rgba(21, 27, 38, 0.1) !important;
}

/*------------------------------------
  Width
------------------------------------*/
.min-width-3 {
  min-width: 1rem;
}

.min-width-35 {
  min-width: 7rem;
}

.max-width-3 {
  max-width: 1rem;
}

.max-width-4 {
  max-width: 1.5rem;
}

.max-width-5 {
  max-width: 2rem;
}

.max-width-6 {
  max-width: 2.5rem;
}

.max-width-7 {
  max-width: 3rem;
}

.max-width-8 {
  max-width: 3.5rem;
}

.max-width-9 {
  max-width: 4rem;
}

.max-width-10 {
  max-width: 5rem;
}

.max-width-12 {
  max-width: 6rem;
}

.max-width-14 {
  max-width: 7rem;
}

.max-width-18 {
  max-width: 9rem;
}

@media (min-width: 576px) {
  .max-width-sm-40 {
    max-width: 20rem;
  }
}

@media (min-width: 992px) {
  .vw-lg-50 {
    width: 50vw !important;
  }
}

/*------------------------------------
  Height
------------------------------------*/
.min-height-320 {
  min-height: 20rem;
}

.min-height-400 {
  min-height: 25rem;
}

.height-100vh {
  height: 100vh;
}

.height-400 {
  height: 25rem;
}

.height-550 {
  height: 34.375rem;
}

.height-600 {
  height: 37.5rem;
}

.min-height-280 {
  min-height: 17.5rem;
}

@media (min-width: 576px) {
  .height-100vh--sm {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .height-100vh--md {
    height: 100vh;
  }
  .h-md-100 {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .h-lg-100 {
    height: 100%;
  }
  .height-15vh--lg {
    height: 15vh;
  }
  .height-85vh--lg {
    height: 85vh;
  }
  .height-100vh--lg {
    height: 100vh;
  }
  .height-600--lg {
    height: 37.5rem;
  }
}

@media (min-width: 1200px) {
  .height-100vh--xl {
    height: 100vh;
  }
}

/*------------------------------------
  Space
------------------------------------*/
.space-1 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.space-1-top {
  padding-top: 2rem;
}

.space-1-bottom {
  padding-bottom: 2rem;
}

.space-2 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.space-2-top {
  padding-top: 4rem;
}

.space-2-bottom {
  padding-bottom: 4rem;
}

.space-3 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.space-3-top {
  padding-top: 8rem;
}

.space-3-bottom {
  padding-bottom: 8rem;
}

.space-4 {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}

.space-4-top {
  padding-top: 12.5rem;
}

.space-4-bottom {
  padding-bottom: 12.5rem;
}

.space-5 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.space-5-top {
  padding-top: 15rem;
}

.space-5-bottom {
  padding-bottom: 15rem;
}

.space-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.space-0-top {
  padding-top: 0;
}

.space-0-bottom {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .space-3--sm {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .space-3-bottom--sm {
    padding-bottom: 8rem;
  }
  .space-4-top--sm {
    padding-top: 12.5rem;
  }
  .space-5--sm {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}

@media (min-width: 768px) {
  .space-2-bottom-0--md {
    padding-bottom: 0;
  }
  .space-3--md {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .space-3-top--md {
    padding-top: 8rem;
  }
  .space-3-bottom--md {
    padding-bottom: 8rem;
  }
  .space-4--md {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .space-4-top--md {
    padding-top: 12.5rem;
  }
  .space-5--md {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .space-5-top--md {
    padding-top: 15rem;
  }
}

@media (min-width: 992px) {
  .space-1--lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .space-3--lg {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .space-3-top--lg {
    padding-top: 8rem;
  }
  .space-3-bottom--lg {
    padding-bottom: 8rem;
  }
  .space-4--lg {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .space-4-top--lg {
    padding-top: 12.5rem;
  }
  .space-4-bottom--lg {
    padding-bottom: 12.5rem;
  }
  .space-5--lg {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .space-5-top--lg {
    padding-top: 15rem;
  }
  .space-0--lg {
    padding-top: 0;
    padding-bottom: 0;
  }
  .space-0-top--lg {
    padding-top: 0;
  }
  .space-0-bottom--lg {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .space-3-bottom--xl {
    padding-bottom: 8rem;
  }
  .space-4-top--xl {
    padding-top: 12.5rem;
  }
}

/*------------------------------------
  Stroke Colors
------------------------------------*/
.stroke-primary {
  stroke: #21c87a !important;
}

.stroke-white {
  stroke: #fff !important;
}

.stroke-gray-100 {
  stroke: #f5f8fb !important;
}

.stroke-gray-150 {
  stroke: #bfc3ca !important;
}

.stroke-gray-200 {
  stroke: #8f95a0 !important;
}

.stroke-gray-300 {
  stroke: #e3e6f0 !important;
}

.stroke-gray-400 {
  stroke: #d4d7dd !important;
}

.stroke-gray-500 {
  stroke: #adb5bd !important;
}

.stroke-gray-600 {
  stroke: #8c98a4 !important;
}

.stroke-gray-700 {
  stroke: #646f79 !important;
}

/*------------------------------------
  Font Size
------------------------------------*/
.font-size-base {
  font-size: 1rem !important;
}

.font-size-inherit {
  font-size: inherit !important;
}

.font-size-13 {
  font-size: .8125rem;
}

.font-size-14 {
  font-size: .875rem;
}

.font-size-18 {
  font-size: 1.125rem;
}

.font-size-28 {
  font-size: 1.75rem;
}

.font-size-32 {
  font-size: 2rem;
}

@media (max-width: 991.98px) {
  .font-size-48--md-down {
    font-size: 3rem;
  }
}

/*------------------------------------
  Font Weight
------------------------------------*/
.font-weight-medium {
  font-weight: 500 !important;
}

/*------------------------------------
  Font Style
------------------------------------*/
.font-normal {
  font-style: normal;
}

/*------------------------------------
  Font Family
------------------------------------*/
.font-family-charm {
  font-family: "Charm", cursive !important;
}

.text-lh-xs {
  line-height: 1.2;
}

.text-lh-sm {
  line-height: 1.5;
}

/*------------------------------------
  Text Colors
------------------------------------*/
.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white-70[href]:hover {
  color: #fff;
}

.text-gray-700 {
  color: #646f79;
}

.text-secondary[href]:hover {
  color: #21c87a !important;
}

/*------------------------------------
  Z-Index
------------------------------------*/
.z-index-minus-1 {
  z-index: -1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

/*------------------------------------
  Others
------------------------------------*/
.cursor-pointer {
  cursor: pointer;
}

/*------------------------------------
  Wizard
------------------------------------*/
.wizard-steps {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.wizard-steps::after {
  content: "";
  position: absolute;
  top: 3.25rem;
  left: 5rem;
  right: 5rem;
  height: 2px;
  background-color: #f5f8fb;
  z-index: 1;
}

.wizard-steps-item {
  text-align: center;
  z-index: 2;
  font-weight: bold;
  font-size: 1.25rem;
  color: #8c98a4;
}

.wizard-steps-item.active {
  color: #212529;
}

.wizard-steps-num {
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -1rem;
  line-height: .7;
  background-color: #fff;
}

.wizard-steps-num-wrapper {
  display: inline-block;
  background-color: #fff;
  padding: 1.5rem;
}

.wizard-steps-num-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.wizard-steps-item.active .wizard-steps-num {
  background-color: #21c87a;
}

.wizard-steps-item.active .wizard-steps-num-inner {
  color: #fff;
}

.wizard-steps-title {
  font-weight: normal;
}

/*------------------------------------
  Animate.css
------------------------------------*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
            transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 25%, 0);
            transform: translate3d(0, 25%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 0.625rem, 0);
            transform: translate3d(0, 0.625rem, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 0.625rem, 0);
            transform: translate3d(0, 0.625rem, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
}

/*------------------------------------
  Cubeportfolio
------------------------------------*/
.u-cubeportfolio .u-cubeportfolio__item {
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  margin: 0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 1rem;
}

.u-cubeportfolio .u-cubeportfolio__item:hover {
  color: #151b26;
}

.u-cubeportfolio .u-cubeportfolio__item.cbp-filter-item-active {
  color: #21c87a;
  background-color: transparent;
  border: transparent;
}

.u-cubeportfolio .cbp-l-loadMore-stop {
  pointer-events: none;
}

.u-cubeportfolio .cbp-caption-zoom .cbp-caption-activeWrap {
  background-color: rgba(255, 255, 255, 0.7);
}

/* Arrow */
.u-cubeportfolio .cbp-nav-controls {
  position: static;
}

.u-cubeportfolio .cbp-nav-prev,
.u-cubeportfolio .cbp-nav-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  z-index: 1;
  line-height: 0;
  display: inline-block;
  color: #fff;
  background-color: #646f79;
  width: 2.75rem;
  height: 2.75rem;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.u-cubeportfolio .cbp-nav-prev:hover,
.u-cubeportfolio .cbp-nav-next:hover {
  color: #fff;
  background-color: #21c87a;
}

.u-cubeportfolio .cbp-nav-prev {
  left: .5rem;
}

.u-cubeportfolio .cbp-nav-next {
  right: .5rem;
}

/* Pagination */
.u-cubeportfolio .cbp-nav-pagination {
  bottom: -3rem;
}

.u-cubeportfolio .cbp-nav-pagination-item {
  width: 1rem;
  height: 1rem;
  background-color: #8c98a4;
  border: 3px solid transparent;
  border-radius: 50%;
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  transition: 0.3s;
}

.u-cubeportfolio .cbp-nav-pagination-active {
  background-color: transparent;
  border-color: #8c98a4;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

/*------------------------------------
  Custombox
------------------------------------*/
.custombox-lock {
  overflow: auto;
}

/*------------------------------------
  DatePicker
------------------------------------*/
.u-datepicker {
  position: relative;
}

.u-datepicker .flatpickr-calendar {
  top: 3.75rem !important;
  left: 0 !important;
  box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.25);
}

.u-datepicker .flatpickr-calendar::before, .u-datepicker .flatpickr-calendar::after {
  display: none;
}

.u-datepicker .flatpickr-months {
  position: relative;
  background-color: #21c87a;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: .75rem;
}

.u-datepicker .flatpickr-months .cur-month,
.u-datepicker .flatpickr-months .cur-year {
  color: #fff;
}

.u-datepicker .flatpickr-current-month,
.u-datepicker .flatpickr-prev-month,
.u-datepicker .flatpickr-next-month {
  padding: 0;
}

.u-datepicker .flatpickr-prev-month,
.u-datepicker .flatpickr-next-month {
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  height: 24px;
}

.u-datepicker .flatpickr-prev-month.flatpickr-prev-month,
.u-datepicker .flatpickr-next-month.flatpickr-prev-month {
  left: .75rem;
}

.u-datepicker .flatpickr-prev-month.flatpickr-next-month,
.u-datepicker .flatpickr-next-month.flatpickr-next-month {
  right: .75rem;
}

.u-datepicker .flatpickr-prev-month svg,
.u-datepicker .flatpickr-next-month svg {
  fill: rgba(255, 255, 255, 0.7);
}

.u-datepicker .flatpickr-prev-month:hover svg,
.u-datepicker .flatpickr-next-month:hover svg {
  fill: #fff;
}

.u-datepicker .flatpickr-weekday {
  color: #151b26;
  text-transform: uppercase;
  font-weight: 500;
}

.u-datepicker .flatpickr-day {
  color: #151b26;
  border-color: transparent;
}

.u-datepicker .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.u-datepicker .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #21c87a;
}

.u-datepicker .flatpickr-day:focus, .u-datepicker .flatpickr-day:hover {
  color: #21c87a;
  background-color: rgba(33, 200, 122, 0.1);
}

.u-datepicker .flatpickr-day.inRange {
  background-color: #f5f8fb;
  box-shadow: -0.3125rem 0 0 #f5f8fb, 0.3125rem 0 0 #f5f8fb;
}

.u-datepicker .flatpickr-day.today {
  color: #fff;
  background-color: #21c87a;
}

.u-datepicker .flatpickr-day.prevMonthDay, .u-datepicker .flatpickr-day.nextMonthDay {
  color: #e3e6f0;
}

.u-datepicker .flatpickr-day.prevMonthDay:focus, .u-datepicker .flatpickr-day.prevMonthDay:hover, .u-datepicker .flatpickr-day.nextMonthDay:focus, .u-datepicker .flatpickr-day.nextMonthDay:hover {
  color: #adb5bd;
  background-color: #e3e6f0;
}

.u-datepicker .flatpickr-day.disabled {
  color: #e3e6f0;
}

.u-datepicker .flatpickr-day.disabled:hover {
  color: #adb5bd;
  background-color: #e3e6f0;
}

.u-datepicker .flatpickr-day.selected, .u-datepicker .flatpickr-day.startRange, .u-datepicker .flatpickr-day.endRange {
  color: #21c87a;
  background-color: transparent;
  border-color: #21c87a;
}

.u-datepicker .flatpickr-day.selected:focus.prevMonthDay, .u-datepicker .flatpickr-day.selected:focus.nextMonthDay, .u-datepicker .flatpickr-day.selected:hover.prevMonthDay, .u-datepicker .flatpickr-day.selected:hover.nextMonthDay, .u-datepicker .flatpickr-day.startRange:focus.prevMonthDay, .u-datepicker .flatpickr-day.startRange:focus.nextMonthDay, .u-datepicker .flatpickr-day.startRange:hover.prevMonthDay, .u-datepicker .flatpickr-day.startRange:hover.nextMonthDay, .u-datepicker .flatpickr-day.endRange:focus.prevMonthDay, .u-datepicker .flatpickr-day.endRange:focus.nextMonthDay, .u-datepicker .flatpickr-day.endRange:hover.prevMonthDay, .u-datepicker .flatpickr-day.endRange:hover.nextMonthDay {
  color: #21c87a;
  background-color: transparent;
}

.u-datepicker .flatpickr-day.selected.startRange, .u-datepicker .flatpickr-day.selected.endRange, .u-datepicker .flatpickr-day.startRange.startRange, .u-datepicker .flatpickr-day.startRange.endRange, .u-datepicker .flatpickr-day.endRange.startRange, .u-datepicker .flatpickr-day.endRange.endRange {
  color: #fff;
  background-color: #21c87a;
}

.u-datepicker .numInputWrapper span.arrowUp,
.u-datepicker .numInputWrapper span.arrowDown {
  display: none;
}

.u-datepicker .numInputWrapper:hover {
  background-color: transparent;
}

/* Positions */
.u-datepicker--top .flatpickr-calendar {
  top: auto !important;
  bottom: 3.5rem !important;
}

/*------------------------------------
  Dzsparallaxer
------------------------------------*/
.dzsparallaxer {
  color: inherit;
}

/*--------------------------------------------------
  Fancybox
----------------------------------------------------*/
.u-fancybox-theme .fancybox-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
  position: static;
}

.u-fancybox-theme .fancybox-bg {
  background-color: #151b26;
}

.fancybox-controls--canzoomIn .fancybox-placeholder,
.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: inherit;
}

.fancybox-slide.has-animation {
  display: block;
}

.fancybox-is-sliding .fancybox-slide.has-animation,
.fancybox-slide--current.has-animation,
.fancybox-slide--next.has-animation,
.fancybox-slide--previous.has-animation {
  display: none;
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated,
.fancybox-slide--current.has-animation.animated,
.fancybox-slide--next.has-animation.animated,
.fancybox-slide--previous.has-animation.animated {
  display: block;
}

.u-blur-30 {
  -webkit-filter: blur(1.875rem);
          filter: blur(1.875rem);
}

/*------------------------------------
  Slick
------------------------------------*/
[data-lazy] {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slick-cloned [data-scs-animation-in] {
  opacity: 0;
}

.u-slick .js-next,
.u-slick .js-prev {
  opacity: 0;
}

.u-slick.slick-initialized {
  background: none;
}

.u-slick.slick-initialized .js-slide,
.u-slick.slick-initialized .js-thumb {
  visibility: visible;
  height: auto;
  overflow: visible;
}

.u-slick.slick-initialized .js-next,
.u-slick.slick-initialized .js-prev {
  opacity: 1;
}

.u-slick .js-slide,
.u-slick .js-thumb {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.u-slick .js-slide:first-child,
.u-slick .js-thumb:first-child {
  height: auto;
}

/*------------------------------------
  Typed.js
------------------------------------*/
.typed-cursor-none .typed-cursor {
  display: none;
}

.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
          animation: blink 0.7s infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*------------------------------------
  File for your custom SCSS style
------------------------------------*/

body,
html {
    font-family: 'Montserrat', sans-serif;
}

body,p,li,div,td {
    color:#333;
    font-size:1.3rem;
}

.login-background p,.login-background div, input, .srv-validation-message, .js-form-message p {
    font-size:1rem;
}

p,ul,ol {
    line-height:2rem;
    margin-bottom:2rem;
}

ul{
    list-style-type:square;
    list-style-position:inside;
    padding-left:10px;
    margin-left:10px;
}

ul.list-unstyled{
    padding-left:0;
    margin-left:0;
}

a {
    color: #F7941E!important;
}
a:hover {
    color: #FBB32B!important;
}

header a {
    color: #37336D!important;
}
header a:hover {
    color: #FBB32B!important;
}

.custom-color {
    color: #F7941E !important;
}

.custom-bg-color {
    background-color: #F7941E !important;
}

.custom-border-color {
    border-color: #F7941E !important;
}

.login-background {
    background-image: linear-gradient(90deg, rgba(94, 131, 195, 0.7) 0%, rgba(146, 146, 146, 0.5) 100%);
}

.home-background {
    background-image: linear-gradient(90deg, rgba(94, 131, 195, 0.7) 0%, rgba(146, 146, 146, 0.5) 100%);
}

.btn-primary {
    color: #fff !important;
    background-color: #F7941E !important;
    border-color: #F7941E !important;
}
.btn-primary:hover {
    color: #fff !important;
    background-color: #fbb32b !important;
    border-color: #fbb32b !important;
}

.btn-secondary {
    color: #fff !important;
    background-color: #2A3389 !important;
    border-color: #2A3389 !important;
}

.btn-secondary:hover {
    color: #fff !important;
    background-color: #4d5cc8 !important;
    border-color: #4d5cc8 !important;
}

.text-lineheight-normal{
    line-height: normal !important;
}

.custom-h1 {
    font-size: 36px !important;
}

.custom-h4 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.34px;
    line-height: 29px;
}

.custom-span {
    font-size: 20px;
}

.font-weight-black{
    font-weight:900 !important;
}

.line-height {
    line-height: 50px;
}

.intro-video {
    max-width:640px;
    margin-right:auto;
    margin-left:auto;
}

.u-video-player__icon::before {
    background-color: rgba(255, 255, 255, 0.8);
}

.u-video-player__icon-inner{
    color: #5E83C3;
}
.gamefinder{
    color:#37336D;
}
.bg-purple{
    background-color:#37336D!important;
}
.bg-orange{
    background-color:#FA9720!important;
}

.btn-dark:hover{
    color:#fff!important;
}

.quote {
    font-weight:200;
}

.slick-arrow {
    display: none;
}

.u-header__section,.card2,.card-header2 {
    background-color:#f3f3f7;
}

.u-header__navbar-brand {
    width: 15.375rem;
}

.u-header--bordered .navbar-expand-lg .u-header__navbar-brand {
    border-right: none;
    margin-right: none;
}

.u-header__hamburger {
    -webkit-flex:none!important;
            flex:none!important;
}

#dropdownMenuButton,.dropdown-menu {
    min-width: 12rem;
}

.navbar-nav .navbar-nav {
    margin-left:0 !important
}

.navbar-expand-lg .navbar-nav .u-header__nav-item-btn {
    padding-left:0 !important
}

.COVIDlink {
    background-color: #292567;
}

.COVIDresponse {
    line-height:1rem;
    padding:0.5rem 0;
}

.status-Cancelled{
    display:none;
}

.columnize-2 {
    -webkit-column-count:2;column-count:2;-webkit-column-width: 250px;column-width: 250px;-webkit-column-gap:60px;-moz-column-gap:60px;grid-column-gap:60px;column-gap:60px;-webkit-column-rule:2px solid #efefef;column-rule:2px solid #efefef;
}

.columnize-2 p{
    page-break-inside:avoid;-webkit-column-break-inside:avoid;page-break-inside:avoid;break-inside:avoid;
}

.collapsing {
    transition: height .2s ease;
}

.btn-join{
    box-shadow: 0 0 2.1875rem rgba(0, 0, 0, 0.125);
}

.btn-join:hover{
    box-shadow: 0 0 2.1875rem rgba(0, 0, 0, 0.425);
}

.btn-join span{
    font-size:2rem!important;
}

.btn-facebook,
.btn-twitter {
    padding: 0.625rem 0.5rem;
}

.forgot-pass,
.read-more,
.back {
    cursor: pointer;
    color: #F7941E !important;
}

.modal-header,
.modal-footer {
    border: none;
}

.subtext {
    color: #868E96;
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 21px;
}

#exampleModalCenter .modal-body {
    padding: 0 3rem 3rem 3rem !important;
}

.forget-user-icon {
    font-size: 25px;
}

.close {
    font-size: 25px;
}

.error {
    border: 1px solid #ff0000;
}

.errorIcon,
.fa-minus-circle {
    color: #F7941E !important;
}

.u-clients {
    width: 8rem !important;
}

.slick-track {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #F7941E;
    background-color: #F7941E;
}

.profile-pic {
    border: 3px solid #fff;
}

.create-team-background {
    background-image: url(/static/media/create-team2-bg.2c2d0965.jpg);
    background-repeat: no-repeat;
}

.yourteam {
    background-color: rgba(94, 131, 195, 0.3);
    color: #303a9c;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}

.date {
    border: 1px solid #cdcdcd;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.fileUpload input.upload {
    display: inline-block;
}

.upload {
    border-radius: 50px;
    width: 100px;
    height: 100px;
}

.upload-text {
    font-size: 6px;
}

.hide {
    display: none;
}

.upload-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.none {
    display: none;
}

.block {
    display: block;
}

.form-selector-modern__input:checked~.form-selector-modern__label {
    border-color: #F7941E;
}

.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__title,
.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__subtitle {
    color: #2D3691 !important;
}

.u-shopping-cart-step-form__item.active {
    border-bottom-color: #2D3691;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #fff!important;
    background-color: #F7941E;
    border-color: #F7941E;
}

.btn-outline-light,
.btn-outline-light:hover {
    color: #F7941E;
    border-color: #F7941E;
}

.card-frame.active,
.card-frame:hover {
    border-color: #b5baed;
    box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.25);
}

a:hover {
    color: #F7941E;
    text-decoration: none;
}

.rc-slider {
    margin-top: 25px;
}

.rc-slider-track {
    background-color: #F7941E;
}

.rc-slider-handle,
.rc-slider-dot-active {
    border: solid 2px #F7941E;
}

.age-group {
    margin-top: 10px;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAbCAYAAACAyoQSAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHaADAAQAAAABAAAAGwAAAABRO+PxAAACqElEQVRIDeXV32uNcRzAcbPZRik2v1dkqM2v/IiJJisKuaDkwsViLTdSbvwtFHdSyo1ckPmRJD+aX22JNjfmjDERB/Ob9/t0ntNznnOeh7OZG5969Zzn+/0+z+f5/jxlY0qLKpo3oQFTMBHv8QRd6MZPJEZZYm1+5Rput2A8PuAl0jDxbJRjACfwArHxp0m38Yb1GEQH7NEPBFHNj41oxjccRgpFw6/7XayiwVb04ij6ER1CE/XgEZZjEe7iKwqiWFLLHEp7thbO4Vv49V+QFO+odDR8fhIckYKoiJQ4P22og19vkrE4haJfTXk0HlBwB8vg/A8hL6JJd1I7C2dwA99hz72WEjdpvBIO8+3og+Gk06lsxLWsoG2pCX0uBZ/bjEp0IjdS4TltoGIxzuINRhIuNKemHkuxGr1wT2eGzqvhXluIe3iNkUYfL3DU3M9L4HCbOO0iMWrQkvmVv/+yRQUXh6wd++ApFRcO8X0cgWfCLq8Or3y4FifxEEkxjso2LIAfOxddSJr7NPUO+QoMmtDN3wTn8haSooLKvZgPk/giE8+BicOnFLd5keJuHaocXk+Qj7iOpDBhK+zhJTyDZ+x5zMMe2CYu3PcDmGZSt0ofLEwK58MVfgUmCsIPuAg/ZndQGHN1V9T4ZSZOGpbg+Qn8MEE4YVDXwQ+Huj4oiLlOpXzIpM8xM6ZRuPhY+KbI7wtFysJFldzMwGN7+RST4SoczdjEy+1kp0mv4hN2wK8ZjXC+m9GDbpN6NJ2DXd8Px/1vRTUvakE7PuM0ckvcfxQX03Ycgktb0bDscrSQe18cXRf+TTplduwVjmevuaTcZw6Gfq4b4L6z59GIS+qhXhdp7IHvevEsz/uXibQr6fYArQ+W9ES2sV3/5/H/JHWzDjc88MuH8/Avz/WMDkMlnC8AAAAASUVORK5CYII=);
}

.download {
    border: 1px solid #979797;
}

.download::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAiCAYAAADVhWD8AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAI6ADAAQAAAABAAAAIgAAAACF3GoXAAAFE0lEQVRYCbWXbWhbVRjH771pajFL6pQtQ2EfZDKHW8vWqgNRKfODiBWps1rp2ia5dUVF5gdBwRe0OHxDEAWVJDex6rQBNx3MymArjLIP29BVYR8cbcEPCoqyrSWrTXKvv+eud9ykvWlv0x16c855zvPyP//z9lRVVlhSqdQuS1W7VEXZjItNfEG+Py1FOalo2vd6X9+Iqqp0l1/w5a+kMpkHLdN8S7GsHY4lQWdplyzLCrlk4+g8r+v6CUe2VK0tpeAeTxnGy4pp/iBAmMUpGHgyoGkbE/H49XoisUaNRG7SVPUJwJ0AWLOiqsfT6fQ+t49q7WUzk0ynXwXEmwS4zAz6EolErppjQHSyRkl0wth1wNB31fRlrCoYZqdms9ko9VbTsn5EPw8Tu2Kx2OmlHMu4va8U5ShMzQTr6u7s7e39rZrdomAMw9hhmubbsHA3QCKOA1XTBvR4/DOnv5yapX2NPfYGgMZYynur2SwAg/FejD/GqA4H/1BPQfOtnJzjAOlE5uuEMBktbRjjwi6stsTj8Z+8ANW5B1iSOwrF4ofI5mBBJ/jn7vH+RMLdXVYb8CYTNJjQB6aiPIyRJ5iy01QslV5B+TroerESyLIieygR5KQMwc7TScOI5XK5+sVUy8CgcBczKUWj0exiyiuVNTU1CRtnQXMLV4NxaXp6itP2WKW/MjAg34jBxfb29nylYi391tbWAptX7qb7mWyWOBvYeMMA6nP7vbqBM5nMbSXT/AXFhrpAYC3H94JbcTXbyUymA4a+JHgD36PcWYfFv80MQG4vmuaYAAH5kVAoNL2awSt99cdiB1XLepx4cjTfHR0dtQ+SJg0Y+YLlWc+98hFX+yOdnZ2lSgde/eSBA1Hovtlr3EvOjXxEJg6gzRNTU32ip01MTOxG0MrAWGM4/AI1J9BHyeePMTv7tPiwslW5d96RBvG7pBZ69kiDtdvvhxGxmS/yXISdjp+aW/4sk8fcahE7jaXZRm2Gw+Fjfhythi4bdxpW/8BXo2wX2cBRvouwMrcaAfz6YEUkB8q3tbUVNSiS92etbES/jmrVl+uE+Dfgx37NyYVUOx0IzM7urNW5X3v2zHNiAzsjUmtka8PSIGccdM679K91SWazLeyXZyFjrr6+/lOJp0VCoWEE5zhf2yYnJwevNQjxnxwa2qIWi9+wRAHivtfT0/O7yDU5ztD0FO082dxLZGc5Xvx1MrjaRZiX90gtFE7DyiZIGIlEIq87ccBxpaSy2XuUYvEwSjciMRk4w7Efp89fecHJr6QYkoApyVTqb6pwv643SJ9gA869IX2n4GQdPndS2weFzZrhOhlwn+KrYMRoaGho/X+FwiDUCVNrHEcLapLy7c3NjfIau8EAQkul0/+i37jA5opAJnmU5vs8BwvutbJMj7X7C8W9JD/7ZmZmtpRUdcNiTjE6L0Aqx2DMZELb50ol+ceurAQU5UIwGDzX3d19qWzA1SkD48ih7jLtBekh+2kr8mBM1887upU1E5pCTy6yILP/uXK8Wr8suaqmOD/2FWt+ig2+20uXXOUhdM7wfeul4yX3ByYQkFdWI+X4ejFAAkQ1zUMsVz0J/X6voF7ysg3speSWswR7mHUWmUkK0AWwT2iHlUCgQymVDtpAFOUZHkH7InPbLtX2DUYcugFJHwABTtJcLUBsP/KzkuICZC81QCxmtiJGnPgrYsYxdgABRK0ViOOzphpA97GZH6jJybzx/44KDWfPBddaAAAAAElFTkSuQmCC);
}

.header-text {
    padding: 8rem 0rem 3rem 0rem;
}

.day{
    padding: 1.25rem;
}

.day li {
    list-style: none;
}

.form-selector-modern__label {
    border: none;
}

.updatestatus {
    border-radius: 50px;
    cursor: pointer;
    border-color: #F7941E !important;
}

.match-result {
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.teamlogo {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border: 4px solid #fff;
    border-radius: 0.4em;
    vertical-align: top
}

.upload .img-fluid {
    object-fit: cover !important;
}

.form-selector-modern {
    margin-bottom: 0rem !important;
}

.information {
    border-radius: 50px;
    margin-bottom: 20px;
    background-color: rgba(224, 32, 32, 0.36);
}

.view-history {
    height: 400px;
    overflow-y: scroll;
    overflow-x: none;
}

.dv-star-rating-star {
    font-size: 30px;
    margin-bottom: 0px;
}

.updatemodal {
    margin-bottom: 35px;
}

.update-cover-image {
    position: absolute;
    top: 10%;
    right: 0%;
    border-color: #F7941E !important;
    border-radius: 50px;
    color: #F7941E
}

.update-cover-image:hover {
    background-color: transparent;
    border-color: #F7941E;
}

.gradient-teamlist{
  position: relative;
  z-index: 1;
}
.gradient-teamlist::before {
  position: absolute;
  top: 25%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 75%;
  content: "";
  background-image: linear-gradient(0deg, rgba(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.btn,.rounded {
    border-radius: 0.4em;
}

.btn {
    font-weight: 700;
}

.btn-outline-primary {
    border-color: #F7941E !important;
    background-color: #fff !important;
    color: #F7941E !important;
}
.btn-outline-primary:hover {
    background-color: #F7941E !important;
    color: #fff !important;
}

.dropdown-toggle .fa-user-circle {
    color: #F7941E !important;
}

.leaguehistory {
    max-height: auto;
}

.show>.btn-outline-primary.dropdown-toggle {
    background-color: transparent;
    border-color: #F7941E;
}

.slick-arrow {
    display: none !important;
}

.react-calendar {
    position: absolute;
}

.react-calendar__navigation__arrow {
    display: none;
}

.react-datepicker *,.rc-time-picker-panel *{
    font-size: 1rem !important;
}

.rc-time-picker-panel-select{
    width: 66px;
}
.rc-time-picker-panel-narrow{
    max-width: 140px;
}

.react-datepicker__input-container input:first-child,
.js-focus-state input:first-child {
    width: 100% !important;
    height: 53.19px !important;
    border-radius: 0.25rem !important;
    border: 1px solid #e3e6f0;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.custom-select:disabled {
    color: #8c98a4;
    background-color: transparent;
}

.custom-select {
    color: #000;
}

.navtext {
    color: #37336D;
    font-size: 1.125rem;
    font-weight:700;
}

.bg-img-hero-bottom {
    background-position: 100%;
}

.react-datepicker-popper {
    z-index: 999;
}

.check::before {
    color: #fff;
    border: 5px solid #F7941E;
}

.rc-time-picker {
    width: 100%;
}

.rc-time-picker-input {
    height: 53.19px !important;
}

.rc-time-picker-panel {
    width: auto;
}

.rc-time-picker-clear {
    display: none;
}

.status-waiting {
    border: 2px solid !important;
}

.btn-outline-light:disabled {
    color: #F7941E;
}

.js-focus-state input,
.react-datepicker__input-container input {
    padding: 0.75rem 1rem;
}

.form-control::-webkit-input-placeholder, .input-group input::-webkit-input-placeholder {
    color: #cdcdcd;
}

.form-control:-ms-input-placeholder, .input-group input:-ms-input-placeholder {
    color: #cdcdcd;
}

.form-control::placeholder,
.input-group input::placeholder {
    color: #cdcdcd;
}

.aboutus hr {
    width: 30%
}

.the-origin {
    background-image: url(/static/media/kenny-wood-richmond.56b89231.jpg);
    min-height: 300px;
    height: 100%;
}

.why-carousel{
    min-height: 200px;
    height: 100%;
}

.why-carousel div{
    min-height: 350px;
    height: 100%;
}

.needs-gamefinder1 {
    background-image: url(/static/media/gf365-boys-baseball-hitting.57455484.jpg);
}

.needs-gamefinder2 {
    background-image: url(/static/media/gf365-coach-boys-basketball-team.718b71f4.jpg);
}

.needs-gamefinder3 {
    background-image: url(/static/media/gf365-girls-baseball-team-smiling.978bd647.jpg);
}

.needs-gamefinder4 {
    background-image: url(/static/media/gf365-boys-soccer.692b7473.jpg);
}

.needs-gamefinder5 {
    background-image: url(/static/media/gf365-girls-indoor-volleyball.ceb565f8.jpg);
}
.comparison {
    background-image: url(/static/media/comparison.3b6cee60.jpg);
    min-height: 300px;
    height: 100%;
}

.clear {
    clear: both
}

.bg-light-purple {
    background-color: #bbcde4;
}

.srv-validation-message {
    color: #ff0000;
}

.custom-file-label {
    white-space: nowrap;
    overflow: hidden;
}

.location-box-cover {
    width: 100%;
}

.custom-style-list {
    list-style-type: none;
}

.google-covert {
    position: absolute;
    z-index: 1;
}

.style-unordered-list {
    background-color: #fff;
}

.zip {
    z-index: 0;
}

#char {
    z-index: 0;
}

.react-calendar {
    z-index: 1;
}

.spinner {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.google-places-autocomplete__input {
    box-shadow: none;
}

.createScrimmmage {
    background: linear-gradient(135.39deg, #5E83C3 0%, #929292 100%);
    padding: 0px 75px;
}
.outgoing-address{
    overflow: hidden;
    text-overflow: ellipsis;
-webkit-line-clamp: 1;
display: -webkit-box;
-webkit-box-orient: vertical;
}
.location-name{
    overflow: hidden;
    height:60px;
    text-overflow: ellipsis;
-webkit-line-clamp: 2;
display: -webkit-box;
-webkit-box-orient: vertical;
}

.create-scrimmage-btn,.email-coach-btn{
    background-color: #2D3691 !important;
    border: 2px solid #2D3691 !important;
    color:#fff!important;
}

.create-scrimmage-btn:hover,.email-coach-btn:hover{
    background-color: #4D5CC8 !important;
    border: 2px solid #4D5CC8 !important;
    color:#fff!important;
}

.pricing-span-bg{
    background-color: #fff;
    color: #2a3389;
    font-size: 18px;
    line-height: 24px;
}
.price-color,.header-color{
    color: #2a3389;
}

.price-duration{
    color: #2a3389;
    font-size:12px;
    line-height: 21.76px;
}
.stripe button span{
    //background-image:linear-gradient(180deg, rgba(247,148,30,1) 0%, rgba(247,148,30,1) 100%) !important;
    //border-radius: 0.25rem !important;
}
.stripe button{
    //background: linear-gradient(180deg, rgba(247,148,30,1) 0%, rgba(247,148,30,1) 100%) !important;
    //border-radius: 0.25rem !important;
    //width: 100% !important;
}
.addteambtntext{
   font-size: 16px;
}
.day-checkbox{
    opacity:1;
    z-index: 1;
    width:12%;
}
.rc-time-picker-input{
font-size:1rem;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #F7941E;
}
.dayscount{
    left:0px;
    top:-14px;
}
.dayremaining{
    background-color: #303a9c;
    color:#fff;
    border-radius:50px;
}

.expired{
    background-color: #da4b48;
    color:#fff;
    border-radius:50px;
}

.react-calendar{
    width:100%;
    position:relative;
}
label{
    font-size:14px;
}
.custom-btn-color{
    background-color: #2D3691 !important;
    border-color: #2D3691 !important;
}
.spinner-grow{
    background-color: #F7941E;
}
#select-color{
    color:#cdcdcd !important;
}
.select-color{
    color:#cdcdcd;
}
.additionError{
    color:#ff0000;
    font-size: 1rem;
}
.custom-select:disabled {
    color: #000;
}
.about-u-label{
    font-size:18px;
}
.custom-select{
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%232d3691%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E") !important;
}
.text-last{
    color:#6D7278;
}

.Toastify__toast *{
  color: #fff!important;
  text-align:center;
  white-space:pre-line!important;
}

.Toastify__toast{
  border-radius:5px;
  background:#37336D!important;
  border:1px solid #fff!important;
  font-family:inherit!important;
  font-size: 16px;
  padding:1.7em 0.7em!important;
}

.Toastify__close-button{
  position:absolute!important;
  top:10px;
  right:10px;
}

.footer {
  border-top: 1px solid #E3E6F0;
}

.mailchimp input[type="text"],.mailchimp input[type="email"]{
  font-size: 1rem;
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 0.5rem 1rem;
  color: #151b26;
  border-radius: 0.25rem;
  border: 1px solid #e3e6f0;
  width: 100%;
  min-width: 200px;
  max-width: 60%;
}

.mailchimp input[type="text"]:focus,.mailchimp input[type="email"]:focus{
  color: #151b26;
}

.mailchimp button[type="submit"]{
  display: inline-block;
  background-color: #2A3389;
  border: 0.1rem solid #2A3389;
  border-radius: 0.25rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  margin: 0;
  line-height: 1.2rem;
  padding: 0.75rem 1rem;
}

.mailchimp button[type="submit"]:hover{
  background-color: #4d5cc8;
  border-color: #4d5cc8;
}

.mailchimp .msg-alert {
  max-width:60%;
}

.mailchimp .msg-alert p {
  font-size: 0.8rem;
  line-height: normal;
  margin: 5px;
}

@media(max-width:991px) {

    h2, .h2 {
      font-size: 1.7rem;
    }
    .aboutus .row {
        margin-bottom: 0rem;
    }    
    .mobile-hide{
        display:none;
    }
    .custom-padding{
        padding:0.2rem !important;
    }
    .day-checkbox{
        display:none;
    }
}

.special{
    height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.cost-height{
    height:45px;
}

@media(max-width:767px) {
    .team-name {
        font-size: 2rem;
    }
    .u-header__navbar-brand-mobile {
        width: 12rem;
        margin: 10px 0px;
    }
    .header-text {
        padding: 0rem;
    }
    .space-2-bottom{
        padding-bottom:0rem !important;
    }
    .mailchimp input[type="text"],.mailchimp input[type="email"]{
      margin: 5px;
      text-align: center;
    }
}

@media (min-width: 576px) {
    .modal-dialog-view-history {
        max-width: 800px;
    }
    .seaparator{
    border-top: .5px solid #c4c9f7;
    padding-left: 10px;
    padding-right: 10px;
    
    }
    .price-heading{
      font-size: 30px    !important;
      line-height: 39px;
    }
    #dropdownMenuButton,.dropdown-menu {
        left: 0 !important;
    }
}
