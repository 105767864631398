@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body,
html {
    font-family: 'Montserrat', sans-serif;
}

body,p,li,div,td {
    color:#333;
    font-size:1.3rem;
}

.login-background p,.login-background div, input, .srv-validation-message, .js-form-message p {
    font-size:1rem;
}

p,ul,ol {
    line-height:2rem;
    margin-bottom:2rem;
}

ul{
    list-style-type:square;
    list-style-position:inside;
    padding-left:10px;
    margin-left:10px;
}

ul.list-unstyled{
    padding-left:0;
    margin-left:0;
}

a {
    color: #F7941E!important;
}
a:hover {
    color: #FBB32B!important;
}

header a {
    color: #37336D!important;
}
header a:hover {
    color: #FBB32B!important;
}

.custom-color {
    color: #F7941E !important;
}

.custom-bg-color {
    background-color: #F7941E !important;
}

.custom-border-color {
    border-color: #F7941E !important;
}

.login-background {
    background-image: linear-gradient(90deg, rgba(94, 131, 195, 0.7) 0%, rgba(146, 146, 146, 0.5) 100%);
}

.home-background {
    background-image: linear-gradient(90deg, rgba(94, 131, 195, 0.7) 0%, rgba(146, 146, 146, 0.5) 100%);
}

.btn-primary {
    color: #fff !important;
    background-color: #F7941E !important;
    border-color: #F7941E !important;
}
.btn-primary:hover {
    color: #fff !important;
    background-color: #fbb32b !important;
    border-color: #fbb32b !important;
}

.btn-secondary {
    color: #fff !important;
    background-color: #2A3389 !important;
    border-color: #2A3389 !important;
}

.btn-secondary:hover {
    color: #fff !important;
    background-color: #4d5cc8 !important;
    border-color: #4d5cc8 !important;
}

.text-lineheight-normal{
    line-height: normal !important;
}

.custom-h1 {
    font-size: 36px !important;
}

.custom-h4 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.34px;
    line-height: 29px;
}

.custom-span {
    font-size: 20px;
}

.font-weight-black{
    font-weight:900 !important;
}

.line-height {
    line-height: 50px;
}

.intro-video {
    max-width:640px;
    margin-right:auto;
    margin-left:auto;
}

.u-video-player__icon::before {
    background-color: rgba(255, 255, 255, 0.8);
}

.u-video-player__icon-inner{
    color: #5E83C3;
}
.gamefinder{
    color:#37336D;
}
.bg-purple{
    background-color:#37336D!important;
}
.bg-orange{
    background-color:#FA9720!important;
}

.btn-dark:hover{
    color:#fff!important;
}

.quote {
    font-weight:200;
}

.slick-arrow {
    display: none;
}

.u-header__section,.card2,.card-header2 {
    background-color:#f3f3f7;
}

.u-header__navbar-brand {
    width: 15.375rem;
}

.u-header--bordered .navbar-expand-lg .u-header__navbar-brand {
    border-right: none;
    margin-right: none;
}

.u-header__hamburger {
    flex:none!important;
}

#dropdownMenuButton,.dropdown-menu {
    min-width: 12rem;
}

.navbar-nav .navbar-nav {
    margin-left:0 !important
}

.navbar-expand-lg .navbar-nav .u-header__nav-item-btn {
    padding-left:0 !important
}

.COVIDlink {
    background-color: #292567;
}

.COVIDresponse {
    line-height:1rem;
    padding:0.5rem 0;
}

.status-Cancelled{
    display:none;
}

.columnize-2 {
    -webkit-column-count:2;-moz-column-count:2;column-count:2;-webkit-column-width: 250px;-moz-column-width: 250px;column-width: 250px;-webkit-column-gap:60px;-moz-column-gap:60px;column-gap:60px;-webkit-column-rule:2px solid #efefef;-moz-column-rule:2px solid #efefef;column-rule:2px solid #efefef;
}

.columnize-2 p{
    page-break-inside:avoid;-webkit-column-break-inside:avoid;break-inside:avoid;
}

.collapsing {
    transition: height .2s ease;
}

.btn-join{
    box-shadow: 0 0 2.1875rem rgba(0, 0, 0, 0.125);
}

.btn-join:hover{
    box-shadow: 0 0 2.1875rem rgba(0, 0, 0, 0.425);
}

.btn-join span{
    font-size:2rem!important;
}

.btn-facebook,
.btn-twitter {
    padding: 0.625rem 0.5rem;
}

.forgot-pass,
.read-more,
.back {
    cursor: pointer;
    color: #F7941E !important;
}

.modal-header,
.modal-footer {
    border: none;
}

.subtext {
    color: #868E96;
    font-size: 16px;
    letter-spacing: 0.25px;
    line-height: 21px;
}

#exampleModalCenter .modal-body {
    padding: 0 3rem 3rem 3rem !important;
}

.forget-user-icon {
    font-size: 25px;
}

.close {
    font-size: 25px;
}

.error {
    border: 1px solid #ff0000;
}

.errorIcon,
.fa-minus-circle {
    color: #F7941E !important;
}

.u-clients {
    width: 8rem !important;
}

.slick-track {
    display: flex;
    align-items: center;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #F7941E;
    background-color: #F7941E;
}

.profile-pic {
    border: 3px solid #fff;
}

.create-team-background {
    background-image: url("./img/create-team/create-team2-bg.jpg");
    background-repeat: no-repeat;
}

.yourteam {
    background-color: rgba(94, 131, 195, 0.3);
    color: #303a9c;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}

.date {
    border: 1px solid #cdcdcd;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.fileUpload input.upload {
    display: inline-block;
}

.upload {
    border-radius: 50px;
    width: 100px;
    height: 100px;
}

.upload-text {
    font-size: 6px;
}

.hide {
    display: none;
}

.upload-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.none {
    display: none;
}

.block {
    display: block;
}

.form-selector-modern__input:checked~.form-selector-modern__label {
    border-color: #F7941E;
}

.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__title,
.u-shopping-cart-step-form__item.active .u-shopping-cart-step-form__subtitle {
    color: #2D3691 !important;
}

.u-shopping-cart-step-form__item.active {
    border-bottom-color: #2D3691;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #fff!important;
    background-color: #F7941E;
    border-color: #F7941E;
}

.btn-outline-light,
.btn-outline-light:hover {
    color: #F7941E;
    border-color: #F7941E;
}

.card-frame.active,
.card-frame:hover {
    border-color: #b5baed;
    box-shadow: 0 0 2.1875rem rgba(140, 152, 164, 0.25);
}

a:hover {
    color: #F7941E;
    text-decoration: none;
}

.rc-slider {
    margin-top: 25px;
}

.rc-slider-track {
    background-color: #F7941E;
}

.rc-slider-handle,
.rc-slider-dot-active {
    border: solid 2px #F7941E;
}

.age-group {
    margin-top: 10px;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: url("./img/create-scrimmage/upload.png");
}

.download {
    border: 1px solid #979797;
}

.download::after {
    content: url("./img/create-scrimmage/download.png");
}

.header-text {
    padding: 8rem 0rem 3rem 0rem;
}

.day{
    padding: 1.25rem;
}

.day li {
    list-style: none;
}

.form-selector-modern__label {
    border: none;
}

.updatestatus {
    border-radius: 50px;
    cursor: pointer;
    border-color: #F7941E !important;
}

.match-result {
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.teamlogo {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border: 4px solid #fff;
    border-radius: 0.4em;
    vertical-align: top
}

.upload .img-fluid {
    object-fit: cover !important;
}

.form-selector-modern {
    margin-bottom: 0rem !important;
}

.information {
    border-radius: 50px;
    margin-bottom: 20px;
    background-color: rgba(224, 32, 32, 0.36);
}

.view-history {
    height: 400px;
    overflow-y: scroll;
    overflow-x: none;
}

.dv-star-rating-star {
    font-size: 30px;
    margin-bottom: 0px;
}

.updatemodal {
    margin-bottom: 35px;
}

.update-cover-image {
    position: absolute;
    top: 10%;
    right: 0%;
    border-color: #F7941E !important;
    border-radius: 50px;
    color: #F7941E
}

.update-cover-image:hover {
    background-color: transparent;
    border-color: #F7941E;
}

.gradient-teamlist{
  position: relative;
  z-index: 1;
}
.gradient-teamlist::before {
  position: absolute;
  top: 25%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 75%;
  content: "";
  background-image: linear-gradient(0deg, rgba(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
}

.btn,.rounded {
    border-radius: 0.4em;
}

.btn {
    font-weight: 700;
}

.btn-outline-primary {
    border-color: #F7941E !important;
    background-color: #fff !important;
    color: #F7941E !important;
}
.btn-outline-primary:hover {
    background-color: #F7941E !important;
    color: #fff !important;
}

.dropdown-toggle .fa-user-circle {
    color: #F7941E !important;
}

.leaguehistory {
    max-height: auto;
}

.show>.btn-outline-primary.dropdown-toggle {
    background-color: transparent;
    border-color: #F7941E;
}

.slick-arrow {
    display: none !important;
}

.react-calendar {
    position: absolute;
}

.react-calendar__navigation__arrow {
    display: none;
}

.react-datepicker *,.rc-time-picker-panel *{
    font-size: 1rem !important;
}

.rc-time-picker-panel-select{
    width: 66px;
}
.rc-time-picker-panel-narrow{
    max-width: 140px;
}

.react-datepicker__input-container input:first-child,
.js-focus-state input:first-child {
    width: 100% !important;
    height: 53.19px !important;
    border-radius: 0.25rem !important;
    border: 1px solid #e3e6f0;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.custom-select:disabled {
    color: #8c98a4;
    background-color: transparent;
}

.custom-select {
    color: #000;
}

.navtext {
    color: #37336D;
    font-size: 1.125rem;
    font-weight:700;
}

.bg-img-hero-bottom {
    background-position: 100%;
}

.react-datepicker-popper {
    z-index: 999;
}

.check::before {
    color: #fff;
    border: 5px solid #F7941E;
}

.rc-time-picker {
    width: 100%;
}

.rc-time-picker-input {
    height: 53.19px !important;
}

.rc-time-picker-panel {
    width: auto;
}

.rc-time-picker-clear {
    display: none;
}

.status-waiting {
    border: 2px solid !important;
}

.btn-outline-light:disabled {
    color: #F7941E;
}

.js-focus-state input,
.react-datepicker__input-container input {
    padding: 0.75rem 1rem;
}

.form-control::placeholder,
.input-group input::placeholder {
    color: #cdcdcd;
}

.aboutus hr {
    width: 30%
}

.the-origin {
    background-image: url("./img/aboutus/kenny-wood-richmond.jpg");
    min-height: 300px;
    height: 100%;
}

.why-carousel{
    min-height: 200px;
    height: 100%;
}

.why-carousel div{
    min-height: 350px;
    height: 100%;
}

.needs-gamefinder1 {
    background-image: url("./img/why/gf365-boys-baseball-hitting.jpg");
}

.needs-gamefinder2 {
    background-image: url("./img/why/gf365-coach-boys-basketball-team.jpg");
}

.needs-gamefinder3 {
    background-image: url("./img/why/gf365-girls-baseball-team-smiling.jpg");
}

.needs-gamefinder4 {
    background-image: url("./img/why/gf365-boys-soccer.jpg");
}

.needs-gamefinder5 {
    background-image: url("./img/why/gf365-girls-indoor-volleyball.jpg");
}
.comparison {
    background-image: url("./img/aboutus/comparison.jpg");
    min-height: 300px;
    height: 100%;
}

.clear {
    clear: both
}

.bg-light-purple {
    background-color: #bbcde4;
}

.srv-validation-message {
    color: #ff0000;
}

.custom-file-label {
    white-space: nowrap;
    overflow: hidden;
}

.location-box-cover {
    width: 100%;
}

.custom-style-list {
    list-style-type: none;
}

.google-covert {
    position: absolute;
    z-index: 1;
}

.style-unordered-list {
    background-color: #fff;
}

.zip {
    z-index: 0;
}

#char {
    z-index: 0;
}

.react-calendar {
    z-index: 1;
}

.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-places-autocomplete__input {
    box-shadow: none;
}

.createScrimmmage {
    background: linear-gradient(135.39deg, #5E83C3 0%, #929292 100%);
    padding: 0px 75px;
}
.outgoing-address{
    overflow: hidden;
    text-overflow: ellipsis;
-webkit-line-clamp: 1;
display: -webkit-box;
-webkit-box-orient: vertical;
}
.location-name{
    overflow: hidden;
    height:60px;
    text-overflow: ellipsis;
-webkit-line-clamp: 2;
display: -webkit-box;
-webkit-box-orient: vertical;
}

.create-scrimmage-btn,.email-coach-btn{
    background-color: #2D3691 !important;
    border: 2px solid #2D3691 !important;
    color:#fff!important;
}

.create-scrimmage-btn:hover,.email-coach-btn:hover{
    background-color: #4D5CC8 !important;
    border: 2px solid #4D5CC8 !important;
    color:#fff!important;
}

.pricing-span-bg{
    background-color: #fff;
    color: #2a3389;
    font-size: 18px;
    line-height: 24px;
}
.price-color,.header-color{
    color: #2a3389;
}

.price-duration{
    color: #2a3389;
    font-size:12px;
    line-height: 21.76px;
}
.stripe button span{
    //background-image:linear-gradient(180deg, rgba(247,148,30,1) 0%, rgba(247,148,30,1) 100%) !important;
    //border-radius: 0.25rem !important;
}
.stripe button{
    //background: linear-gradient(180deg, rgba(247,148,30,1) 0%, rgba(247,148,30,1) 100%) !important;
    //border-radius: 0.25rem !important;
    //width: 100% !important;
}
.addteambtntext{
   font-size: 16px;
}
.day-checkbox{
    opacity:1;
    z-index: 1;
    width:12%;
}
.rc-time-picker-input{
font-size:1rem;
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: #F7941E;
}
.dayscount{
    left:0px;
    top:-14px;
}
.dayremaining{
    background-color: #303a9c;
    color:#fff;
    border-radius:50px;
}

.expired{
    background-color: #da4b48;
    color:#fff;
    border-radius:50px;
}

.react-calendar{
    width:100%;
    position:relative;
}
label{
    font-size:14px;
}
.custom-btn-color{
    background-color: #2D3691 !important;
    border-color: #2D3691 !important;
}
.spinner-grow{
    background-color: #F7941E;
}
#select-color{
    color:#cdcdcd !important;
}
.select-color{
    color:#cdcdcd;
}
.additionError{
    color:#ff0000;
    font-size: 1rem;
}
.custom-select:disabled {
    color: #000;
}
.about-u-label{
    font-size:18px;
}
.custom-select{
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%232d3691%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E") !important;
}
.text-last{
    color:#6D7278;
}

.Toastify__toast *{
  color: #fff!important;
  text-align:center;
  white-space:pre-line!important;
}

.Toastify__toast{
  border-radius:5px;
  background:#37336D!important;
  border:1px solid #fff!important;
  font-family:inherit!important;
  font-size: 16px;
  padding:1.7em 0.7em!important;
}

.Toastify__close-button{
  position:absolute!important;
  top:10px;
  right:10px;
}

.footer {
  border-top: 1px solid #E3E6F0;
}

.mailchimp input[type="text"],.mailchimp input[type="email"]{
  font-size: 1rem;
  display: inline-block;
  margin: 0 5px 0 0;
  padding: 0.5rem 1rem;
  color: #151b26;
  border-radius: 0.25rem;
  border: 1px solid #e3e6f0;
  width: 100%;
  min-width: 200px;
  max-width: 60%;
}

.mailchimp input[type="text"]:focus,.mailchimp input[type="email"]:focus{
  color: #151b26;
}

.mailchimp button[type="submit"]{
  display: inline-block;
  background-color: #2A3389;
  border: 0.1rem solid #2A3389;
  border-radius: 0.25rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  margin: 0;
  line-height: 1.2rem;
  padding: 0.75rem 1rem;
}

.mailchimp button[type="submit"]:hover{
  background-color: #4d5cc8;
  border-color: #4d5cc8;
}

.mailchimp .msg-alert {
  max-width:60%;
}

.mailchimp .msg-alert p {
  font-size: 0.8rem;
  line-height: normal;
  margin: 5px;
}

@media(max-width:991px) {

    h2, .h2 {
      font-size: 1.7rem;
    }
    .aboutus .row {
        margin-bottom: 0rem;
    }    
    .mobile-hide{
        display:none;
    }
    .custom-padding{
        padding:0.2rem !important;
    }
    .day-checkbox{
        display:none;
    }
}

.special{
    height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.cost-height{
    height:45px;
}

@media(max-width:767px) {
    .team-name {
        font-size: 2rem;
    }
    .u-header__navbar-brand-mobile {
        width: 12rem;
        margin: 10px 0px;
    }
    .header-text {
        padding: 0rem;
    }
    .space-2-bottom{
        padding-bottom:0rem !important;
    }
    .mailchimp input[type="text"],.mailchimp input[type="email"]{
      margin: 5px;
      text-align: center;
    }
}

@media (min-width: 576px) {
    .modal-dialog-view-history {
        max-width: 800px;
    }
    .seaparator{
    border-top: .5px solid #c4c9f7;
    padding-left: 10px;
    padding-right: 10px;
    
    }
    .price-heading{
      font-size: 30px    !important;
      line-height: 39px;
    }
    #dropdownMenuButton,.dropdown-menu {
        left: 0 !important;
    }
}